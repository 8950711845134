import NavBar from '../menus/NavBar';
import styles from  '../styles/Sobre.module.css'
import {FaGithub} from 'react-icons/fa'
import React, { useEffect} from "react";
import Footer from '../menus/Footer';




function LGPD() {
   

    return(
        <>
         <NavBar />
        <div className={styles.conteudo_pgn}>
        <div className={styles.conteudo_edit}>
            <h1>Política de Privacidade</h1>
            <div className={styles.formGroup}>
            <p>   A sua privacidade é importante para nós. É política do SICAP respeitar a sua privacidade 
                em relação a qualquer informação sua que possamos coletar em nosso site, 
                e outros sites que possuímos e operamos.</p></div>
                <div className={styles.formGroup}>
            <p>   Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. 
                Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que 
                estamos coletando e como será usado.</p></div>
                <div className={styles.formGroup}>
            <p>   Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. 
                Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, 
                bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</p></div>
                <div className={styles.formGroup}>
            <p>   Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido 
                pela <a target='blank' href='https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm'>Lei Geral de Proteção de Dados Pessoais (LGPD).</a></p></div>
                <div className={styles.formGroup}>
            <p>   O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que 
                não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por 
                suas respectivas políticas de privacidade.</p></div>
                <div className={styles.formGroup}>
            <p>   Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não 
                possamos fornecer alguns dos serviços desejados.</p></div>
                <div className={styles.formGroup}>
            <p>   O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de 
                privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados 
                do usuário e informações pessoais, entre em contato conosco.</p></div><br/><br/>
        
        </div></div>
        <Footer />
        </>
    )
}

export default LGPD;