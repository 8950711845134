import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from '../styles/Tabela.module.css'; // Importe o módulo de CSS da tabela
import { useNavigate } from 'react-router-dom';
import SideBarAgente from '../menus/SideBar.Agente';
import { BsPencil, BsFillTrashFill } from 'react-icons/bs';

function RequisicaoAgente() {
  const [requisicoes, setRequisicoes] = useState([]);
  const [requisicaoEdit, setRequisicaoEdit] = useState(null);
  const [preferenciais, setPreferenciais] = useState([]); // Defina preferenciais usando useState
  const [especialidades, setEspecialidades] = useState([]); // Defina especialidades usando useState
  const [pacientes, setPacientes] = useState([]); // Defina especialidades usando useState
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [filtroRequisicao, setFiltroRequisicao] = useState("");




  const navegação = useNavigate();
  useEffect(() => {
    const accessTokenAgente = localStorage.getItem("accessTokenAgente");

    if (!accessTokenAgente) {
      // Redirecionar para a página de acesso negado
      navegação("/acessonegado");

      // Mostrar alerta ao usuário após um pequeno atraso
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500); // Ajuste o tempo de atraso conforme necessário
    }

  }, [navegação]);



  useEffect(() => {
    // Fetch requisitions data from API endpoint
    axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao')
      .then(response => {
        setRequisicoes(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleEditar = (id) => {
    // Find the requisition to edit by its ID
    const requisicaoParaEditar = requisicoes.find(requisicao => requisicao.id === id);
    setRequisicaoEdit(requisicaoParaEditar);
    setMostrarFormulario(true);
  };

  const handleExcluir = (id) => {
    const confirmarExclusao = window.confirm("Tem certeza que deseja excluir esta requisição?");
    if (confirmarExclusao)
      axios.delete(`https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao/${id}`)
        .then(response => {
          // Update the requisicoes state to remove the deleted requisition
          setRequisicoes(prevRequisicoes => prevRequisicoes.filter(requisicao => requisicao.id !== id));
          alert("Requisição excluída com sucesso!");
        })
        .catch(error => {
          console.error(error);
          alert("Erro ao excluir requisição.");
        });
  };

  const validarCampos = (requisicao) => {
    if (!validarCamposInternamente(requisicao)) {
      // Exibir mensagem de erro
      return false;
    }
    // Restante da lógica de validação...
    return true; // ou false, dependendo da lógica
  };

  const validarCamposInternamente = (requisicao) => {

    // Validar se Rua, Número e Bairro não estão vazios
    if (
      !requisicao.endereco.rua.trim() ||
      (typeof requisicao.endereco.numero === 'string' && !requisicao.endereco.numero.trim()) ||
      !requisicao.endereco.bairro.trim()
    ) {
      alert("Por favor, preencha os campos de Rua, Número e Bairro.");
      return false;
    }


    // Validar se Descrição dos Sintomas não está vazia e contém apenas letras
    const descricaoSintomasRegex = /^[A-Za-zÀ-ÿ\s]+$/;
    if (!requisicao.descricaoDosSintomas.trim() || !descricaoSintomasRegex.test(requisicao.descricaoDosSintomas)) {
      alert("Por favor, insira uma Descrição dos Sintomas válida contendo apenas letras.");
      return false;
    }

  // Validar RG (11 dígitos com pontos e hífen)
 const rgRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

 if (!rgRegex.test(requisicao.rg)) {
   alert("Por favor, insira um RG válido com 11 dígitos, no formato 000.000.000-00.");
   return;
 }
    // Validar Cartão SUS (15 digits)
    const cartaoSusRegex = /^\d{15}$/;
    if (!cartaoSusRegex.test(requisicao.cartaoSUS)) {
      alert("Por favor, insira um número de Cartão SUS válido com 15 dígitos.");
      return false;
    }

    // Validar CEP (8 digits)
    const cepRegex = /^\d{8}$/;
    if (!cepRegex.test(requisicao.endereco.cep)) {
      alert("Por favor, insira um CEP válido com 8 dígitos.");
      return false;
    }

    // Validar nome completo (apenas letras)
    const nomeRegex = /^[A-Za-zÀ-ÿ\s]+$/;
    if (!nomeRegex.test(requisicao.nomeCompleto)) {
      alert("Por favor, insira um nome completo válido contendo apenas letras.");
      return false;
    }

    // Retornar true se todas as validações passarem
    return true;
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();

    // Lógica de validação dos campos
    if (!validarCampos(requisicaoEdit)) {
      // Exibir mensagem de erro
      return;
    }



    const confirmarEdicao = window.confirm("Tem certeza que deseja salvar as edições?");
    if (confirmarEdicao) {
      // Send PUT request to update requisition data
      axios.put(`https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao/${requisicaoEdit.id}`, requisicaoEdit)
        .then(response => {
          console.log(response.data);
          alert("Requisição atualizada com sucesso!");

          // Fetch updated requisitions data from API endpoint after successful update
          axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao')
            .then(response => {
              setRequisicoes(response.data);
            })
            .catch(error => {
              console.error(error);
            });

          // Clear the requisicaoEdit state to indicate no active editing
          setRequisicaoEdit(null);

          // Reload the page after a short delay (500 milliseconds)
          setTimeout(() => {
            window.location.reload();
          }, 500); // Meio segundo de atraso antes de recarregar a página
        })
        .catch(error => {
          console.error(error);
          alert("Erro ao atualizar requisição.");
        });
    }


  };
  useEffect(() => {
    // Solicitação GET para preferenciais
    axios.get("https://tesw.tecnologiaemsoftwareweb.com.br/api/preferencial")
      .then(response => {
        setPreferenciais(response.data);
      })
      .catch(error => {
        console.error(error);
      });

    // Solicitação GET para especialidades
    axios.get("https://tesw.tecnologiaemsoftwareweb.com.br/api/especialidade")
      .then(response => {
        setEspecialidades(response.data);
      })
      .catch(error => {
        console.error(error);
      });

    // Solicitação GET para especialidades
    axios.get("https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente")
      .then(response => {
        setPacientes(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);



  const filtro_Requisicao = requisicoes.filter((requisicao) => {
    const requisicaoIdMatches = requisicao.paciente && requisicao.paciente.id.toString().includes(filtroRequisicao);

    return requisicaoIdMatches;
  }
  );



  return (
    <>
      <SideBarAgente />
      <div className={styles.containerAdminNavBar}>

        <div className={styles.container}>
          <main>
            <h1>Lista de Requisições</h1>
            <div className={styles.conteudo}>
              <input
                type="text"
                placeholder="Pesquisar por ID do Paciente"
                value={filtroRequisicao}
                onChange={(e) => setFiltroRequisicao(e.target.value)}
              /></div>
            {mostrarFormulario ? (
              <div className={styles.editForm}>
                <form onSubmit={handleEditSubmit}>

                  <div className={styles.formGroup}>
                    <label htmlFor="nomeCompleto">Nome Completo:</label>
                    <input
                      type="text"
                      placeholder="Nome Completo"
                      value={requisicaoEdit?.nomeCompleto || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, nomeCompleto: e.target.value })}
                      required
                    /></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="rg">RG:</label>
                    <input
                      type="text"
                      placeholder="RG"
                      value={requisicaoEdit?.rg || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, rg: e.target.value })}
                      required
                    /></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="Cartão SUS">Cartão SUS:</label>
                    <input
                      type="text"
                      placeholder="Cartão SUS"
                      value={requisicaoEdit?.cartaoSUS || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, cartaoSUS: e.target.value })}
                      required
                    /></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="Descrição dos Sintomas">Descrição dos Sintomas:</label>
                    <input
                      type="text"
                      placeholder="Descrição dos Sintomas"
                      value={requisicaoEdit?.descricaoDosSintomas || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, descricaoDosSintomas: e.target.value })}
                      required
                    /></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="Preferencial ID">Preferencial:</label>
                    <select
                      value={requisicaoEdit?.preferencial.id || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, preferencial: { id: e.target.value } })}
                      required
                    >
                      <option value="">Selecione uma preferencial</option>
                      {preferenciais.map(preferencial => (
                        <option key={preferencial.id} value={preferencial.id}>
                          {preferencial.nome}
                        </option>
                      ))}
                    </select></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="Especialidade ID">Especialidade:</label>
                    <select
                      value={requisicaoEdit?.especialidade.id || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, especialidade: { id: e.target.value } })}
                      required
                    >
                      <option value="">Selecione uma especialidade</option>
                      {especialidades.map(especialidade => (
                        <option key={especialidade.id} value={especialidade.id}>
                          {especialidade.nome}
                        </option>
                      ))}
                    </select></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="Paciente ID">Paciente ID:</label>
                    <select
                      value={requisicaoEdit?.paciente.id || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, paciente: { id: e.target.value } })}
                      required
                    >
                      <option value="">Selecione um paciente</option>
                      {pacientes.map(paciente => (
                        <option key={paciente.id} value={paciente.id}>
                          {`${paciente.id} - ${paciente.nome} (${paciente.email})`}
                        </option>
                      ))}
                    </select></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="Rua">Rua:</label>
                    <input
                      type="text"
                      placeholder="Rua"
                      value={requisicaoEdit?.endereco.rua || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, endereco: { ...requisicaoEdit.endereco, rua: e.target.value } })}
                      required
                    /></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="Número">Número:</label>
                    <input
                      type="text"
                      placeholder="Número"
                      value={requisicaoEdit?.endereco.numero || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, endereco: { ...requisicaoEdit.endereco, numero: e.target.value } })}
                      required
                    /></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="Bairro">Bairro:</label>
                    <input
                      type="text"
                      placeholder="Bairro"
                      value={requisicaoEdit?.endereco.bairro || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, endereco: { ...requisicaoEdit.endereco, bairro: e.target.value } })}
                      required
                    /></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="CEP">CEP:</label>
                    <input
                      type="text"
                      placeholder="CEP"
                      value={requisicaoEdit?.endereco.cep || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, endereco: { ...requisicaoEdit.endereco, cep: e.target.value } })}
                      required
                    /></div>
                  <div className={styles.formGroup}>
                    <label htmlFor="Complemento">Complemento:</label>
                    <input
                      type="text"
                      placeholder="Complemento"
                      value={requisicaoEdit?.endereco.complemento || ''}
                      onChange={(e) => setRequisicaoEdit({ ...requisicaoEdit, endereco: { ...requisicaoEdit.endereco, complemento: e.target.value } })}
                    /></div>

                  <button className={styles.recuoBotao} type="submit">Salvar</button>
                </form><br />
              </div>


            ) : (
              <div>




                <table className={styles.tabela}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>NOME COMPLETO</th>
                      <th>RG</th>
                      <th>CARTÃO SUS</th>
                      <th>DESCRIÇÃO DOS SINTOMAS</th>
                      <th>PREFERENCIAL</th>
                      <th>ESPECIALIDADE</th>
                      <th>PACIENTE ID</th>
                      <th>RUA</th>
                      <th>NÚMERO</th>
                      <th>BAIRRO</th>
                      <th>CEP</th>
                      <th>COMPLEMENTO</th>
                      <th>AÇÕES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtro_Requisicao.map(requisicao => (
                      <tr key={requisicao.id}>
                        <td>{requisicao.id}</td>
                        <td>{requisicao.nomeCompleto}</td>
                        <td>{requisicao.rg}</td>
                        <td>{requisicao.cartaoSUS}</td>
                        <td>{requisicao.descricaoDosSintomas}</td>
                        <td>{requisicao.preferencial.nome}</td>
                        <td>{requisicao.especialidade.nome}</td>
                        <td>{requisicao.paciente.id}</td>
                        <td>{requisicao.endereco.rua}</td>
                        <td>{requisicao.endereco.numero}</td>
                        <td>{requisicao.endereco.bairro}</td>
                        <td>{requisicao.endereco.cep}</td>
                        <td>{requisicao.endereco.complemento}</td>
                        <td><div className={styles.botoesContainer}>
                          <button className={`${styles.botaoRover} ${styles.azul}`} onClick={() => handleEditar(requisicao.id)}>
                            <BsPencil />
                          </button>
                          <button className={`${styles.botaoRover} ${styles.vermelho}`} onClick={() => handleExcluir(requisicao.id)}>
                            <BsFillTrashFill />
                          </button>
                        </div></td>
                      </tr>
                    ))}
                  </tbody>
                </table><br /></div>



            )}
          </main>
        </div>
      </div>

    </>
  )
}


export default RequisicaoAgente;


