import { useNavigate } from "react-router-dom";
import styles from './SideBar.Agente.module.css';
//import logo from '../../img/Sicap.png';
import {  VscMail, VscInfo,VscAccount,VscEmptyWindow  } from "react-icons/vsc";
import { FaUserPen } from "react-icons/fa6";
import { IoExitOutline } from "react-icons/io5";
import { CiViewList } from "react-icons/ci";
//import { FiEdit } from "react-icons/fi";
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import axios from "axios";

function SideBarAgente() {
  const navegação = useNavigate();

  const [agentes, setAgentes] = useState([]);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const accessTokenAgente = localStorage.getItem("accessTokenAgente");

    if (!accessTokenAgente) {
      navegação("/acessonegado");
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500);
    } else {
      // Get user ID from the last log message in the login endpoint
      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/loginagente', {
        headers: {
          Authorization: `Bearer ${accessTokenAgente}`
        }
      })
      .then((response) => {
        const logMessages = response.data.log_messages;
        if (logMessages && logMessages.length > 0) {
          const lastLogMessage = logMessages[logMessages.length - 1];
          const userIdFromLog = extractUserIdFromLog(lastLogMessage);
          setUserId(userIdFromLog); // Set user ID from the last log message
        }
      })
      .catch((error) => {
        console.error(error);
      });

      // Get all patients data
      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/agente', {
        headers: {
          Authorization: `Bearer ${accessTokenAgente}`
        }
      })
      .then((response) => setAgentes(response.data))
      .catch((error) => {
        // Handle request errors as needed
        console.error(error);
      });
    }
  }, [navegação]);

  const extractUserIdFromLog = (logMessage) => {
  const userIdRegex = /Usuário com ID (\d+) acessou/; // Regular expression to match the user ID pattern
  const match = logMessage.match(userIdRegex); // Match the regular expression with the log message

  // Log the original log message and the matched user ID for debugging
  console.log("Original Log Message:", logMessage);
  console.log("Matched User ID:", match ? match[1] : null);

  // Check if there is a match and return the extracted user ID, or return null if there is no match
  return match ? match[1] : null;
};

  

const filteredPatients = agentes.filter((agente) => {
  return agente.id.toString() === userId;
});



// Acessar o nome do paciente logado, se houver um paciente filtrado
const nomeUsuarioLogado = filteredPatients.length > 0 ? filteredPatients[0].nome : '';


  

  const logout = () => {
    // Limpar os dados de autenticação (por exemplo, o token) do armazenamento local
    localStorage.removeItem("accessTokenAgente");

    // Redirecionar para a página de login
    navegação("/login");
  };
    
       

            
    return(
        <div className={styles.SideBarAgente}>

            <ul>
                
            <li className={styles.usuario2}>
        <Link style={{ color: 'inherit', cursor: 'default'}}>
        <span className={styles.icone}><VscAccount  /></span>
        <span className={styles.titulo}>Olá,  {nomeUsuarioLogado}</span>
        </Link>
           </li>
                <li className={styles.usuario}>
                    <Link to="/agente" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><FaUserPen /></span>
                        <span className={styles.titulo}>Editar Perfil</span>
                    </Link>
                </li>
                <li>
                    <Link to="/requisicaoagente" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><CiViewList /></span>
                        <span className={styles.titulo}>Requisições</span>
                    </Link>
                </li>
                <li>
                    <Link to="/ficha" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><VscEmptyWindow /></span>
                        <span className={styles.titulo}>Cadastrar Fichas</span>
                    </Link>
                </li>
                <li>
                    <Link to="/listapaciente" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><VscMail /></span>
                        <span className={styles.titulo}>Enviar Mensagem</span>
                    </Link>
                </li>
                <li>
                    <Link to="/pacientesfichas" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><VscInfo /></span>
                        <span className={styles.titulo}>Requisições/Fichas</span>
                    </Link>
                </li>
               
                <li className={styles.usuario}>
                    <Link to="/login" style={{ color: 'inherit' }} onClick={logout}>
                        <span className={styles.icone}><IoExitOutline /></span>
                        <span className={styles.titulo}>Sair</span>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default SideBarAgente;

