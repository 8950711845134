import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/FormLogin.module.css";
import axios from "axios";

function FormLogin() {
  const navegação = useNavigate();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [pacientes, setPacientes] = useState([]);
  const [agentes, setAgentes] = useState([]);
  // eslint-disable-next-line
  const [accessTokenAgente, setAccessTokenAgente] = useState("");
  // eslint-disable-next-line
  const [accessToken, setAccessToken] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verifica se é admin
    if (email === "admin@gmail.com" && senha === "123") {
      localStorage.setItem("isAdmin", "true");
      alert("Login como admin feito com Sucesso");
      navegação("/cadastroagente");
      return;
    }

    // Verifica primeiro se é um paciente
    const pacienteEncontrado = pacientes.find(
      (paciente) => paciente.email === email && paciente.senha === senha
    );

    if (pacienteEncontrado) {
      try {
        const response = await axios.post("https://tesw.tecnologiaemsoftwareweb.com.br/api/login", {
          email: email,
          senha: senha,
        });

        setAccessToken(response.data.access_token);
        localStorage.setItem("accessToken", response.data.access_token);
        alert("Login feito com Sucesso");
        navegação("/pacientes");
      } catch (error) {
        alert("Erro ao fazer login");
      }
    } else {
      // Se não for um coordenador, verifica se é um agente
      const agenteEncontrado = agentes.find(
        (agente) => agente.email === email && agente.senha === senha
      );

      if (agenteEncontrado) {
        try {
          const response = await axios.post("https://tesw.tecnologiaemsoftwareweb.com.br/api/loginagente", {
            email: email,
            senha: senha,
           });
          
          

          setAccessTokenAgente(response.data.access_tokenAgente);
          localStorage.setItem("accessTokenAgente", response.data.access_tokenAgente);
          alert("Login feito com Sucesso");
          navegação("/agente");
        } catch (error) {
          alert("Erro ao fazer login");
        }
      } else {
        alert("Usuário e senha incorretos");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsePacientes = await axios.get("https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente");
        setPacientes(responsePacientes.data);

        const responseAgentes = await axios.get("https://tesw.tecnologiaemsoftwareweb.com.br/api/agente");
        setAgentes(responseAgentes.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <form className={styles.content_form} onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Senha"
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          required
        />
        <button className={styles.content_formbutton} type="submit">
          Entrar
        </button>
      </form>
    </div>
  );
}

export default FormLogin;
