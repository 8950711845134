import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from '../styles/ListarRequisicao.module.css';
import { useNavigate } from 'react-router-dom';
import SideBar from '../menus/SideBar';
import { BsPencil, BsFillTrashFill } from 'react-icons/bs';
import FormStep1 from "../form/FormStep1";
import FormStep2 from "../form/FormStep2";
import FormStep3 from "../form/FormStep3";



function ListarRequisicao() {
  const [requisicoes, setRequisicoes] = useState([]);
  const [preferenciais, setPreferenciais] = useState([]);
  const [requisicaoEdit, setRequisicaoEdit] = useState(null);
  const [especialidades, setEspecialidades] = useState([]);
  const [pacientes, setPacientes] = useState([]);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true); // Adicione o estado para controle de carregamento
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [filtroRequisicao, setFiltroRequisicao] = useState("");
  



  const [step, setStep] = useState(1);
  const navegação = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navegação("/acessonegado");
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500);
    } else {
      axios
        .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/login", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          const logMessages = response.data.log_messages;
          if (logMessages && logMessages.length > 0) {
            const lastLogMessage = logMessages[logMessages.length - 1];
            const userIdFromLog = extractUserIdFromLog(lastLogMessage);
            setUserId(userIdFromLog);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          console.log("Requisições:", response.data); // Adicione este log para verificar as requisições
          setRequisicoes(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [navegação]);

  const extractUserIdFromLog = (logMessage) => {
    const userIdRegex = /Usuário com ID (\d+) acessou/;
    const match = logMessage.match(userIdRegex);
    console.log("Original Log Message:", logMessage);
    console.log("Matched User ID:", match ? match[1] : null);
    return match ? match[1] : null;
  };

  useEffect(() => {
    axios
      .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/preferencial")
      .then((response) => {
        setPreferenciais(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/especialidade")
      .then((response) => {
        setEspecialidades(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente")
      .then((response) => {
        setPacientes(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const handleEditar = (id) => {
    // Find the requisition to edit by its ID
    const requisicaoParaEditar = requisicoes.find(
      (requisicao) => requisicao.id === id
    );
    setRequisicaoEdit(requisicaoParaEditar);
    setMostrarFormulario(true);
  };

  const handleExcluir = (id) => {
    const confirmarExclusao = window.confirm(
      "Tem certeza que deseja excluir esta requisição?"
    );
    if (confirmarExclusao)
      axios
        .delete(`https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao/${id}`)
        .then((response) => {
          // Update the requisicoes state to remove the deleted requisition
          setRequisicoes((prevRequisicoes) =>
            prevRequisicoes.filter((requisicao) => requisicao.id !== id)
          );
          alert("Requisição excluída com sucesso!");
        })
        .catch((error) => {
          console.error(error);
          alert("Erro ao excluir requisição.");
        });
  };

  const validarCampos = (requisicao) => {
    if (!validarCamposInternamente(requisicao)) {
      // Exibir mensagem de erro
      return false;
    }
    // Restante da lógica de validação...
    return true; // ou false, dependendo da lógica
  };

  const validarCamposInternamente = (requisicao) => {
    // Lógica de validação interna
    // ...

    // Validar se Rua, Número e Bairro não estão vazios
    if (
      !requisicao.endereco.rua.trim() ||
      (typeof requisicao.endereco.numero === "string" &&
        !requisicao.endereco.numero.trim()) ||
      !requisicao.endereco.bairro.trim()
    ) {
      alert("Por favor, preencha os campos de Rua, Número e Bairro.");
      return false;
    }

    // Validar se Descrição dos Sintomas não está vazia e contém apenas letras
    const descricaoSintomasRegex = /^[A-Za-zÀ-ÿ\sçÇ]+$/;
    if (
      !requisicao.descricaoDosSintomas.trim() ||
      !descricaoSintomasRegex.test(requisicao.descricaoDosSintomas)
    ) {
      alert(
        "Por favor, insira uma Descrição dos Sintomas válida contendo apenas letras."
      );
      return false;
    }

      // Validar RG (11 dígitos com pontos e hífen)
const rgRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

if (!rgRegex.test(requisicao.rg)) {
  alert("Por favor, insira um RG válido com 11 dígitos, no formato 000.000.000-00.");
  return;
}
    // Validar Cartão SUS (15 digits)
    const cartaoSusRegex = /^\d{15}$/;
    if (!cartaoSusRegex.test(requisicao.cartaoSUS)) {
      alert("Por favor, insira um número de Cartão SUS válido com 15 dígitos.");
      return false;
    }

    // Validar CEP (8 digits)
    const cepRegex = /^\d{8}$/;
    if (!cepRegex.test(requisicao.endereco.cep)) {
      alert("Por favor, insira um CEP válido com 8 dígitos.");
      return false;
    }

    // Validar nome completo (apenas letras)
    const nomeRegex = /^[A-Za-z\s]+$/;
    if (!nomeRegex.test(requisicao.nomeCompleto)) {
      alert(
        "Por favor, insira um nome completo válido contendo apenas letras."
      );
      return false;
    }

    // Retornar true se todas as validações passarem
    return true;
  };

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();

    // Lógica de validação dos campos
    if (!validarCampos(requisicaoEdit)) {
      // Exibir mensagem de erro
      return;
    }

    const confirmarEdicao = window.confirm(
      "Tem certeza que deseja salvar as edições?"
    );
    if (confirmarEdicao) {
      // Send PUT request to update requisition data
      axios
        .put(
          `https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao/${requisicaoEdit.id}`,
          requisicaoEdit
        )
        .then((response) => {
          console.log(response.data);
          alert("Requisição atualizada com sucesso!");

          // Fetch updated requisitions data from API endpoint after successful update
          axios
            .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao")
            .then((response) => {
              setRequisicoes(response.data);
            })
            .catch((error) => {
              console.error(error);
            });

          // Clear the requisicaoEdit state to indicate no active editing
          setRequisicaoEdit(null);

          // Reload the page after a short delay (500 milliseconds)
          setTimeout(() => {
            window.location.reload();
          },500); // Meio segundo de atraso antes de recarregar a página
        })
        .catch((error) => {
          console.error(error);
          alert("Erro ao atualizar requisição.");
        });
    }
  };
  useEffect(() => {
    // Solicitação GET para preferenciais
    axios
      .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/preferencial")
      .then((response) => {
        setPreferenciais(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    // Solicitação GET para especialidades
    axios
      .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/especialidade")
      .then((response) => {
        setEspecialidades(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    // Solicitação GET para especialidades
    axios
      .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente")
      .then((response) => {
        setPacientes(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  const filteredRequisicoes = requisicoes.filter((requisicao) => {
    const isMatchingPatient = requisicao.paciente.id.toString() === userId;
    const matchesSpecialty =
      requisicao.especialidade &&
      requisicao.especialidade.nome &&
      requisicao.especialidade.nome.toLowerCase().includes(filtroRequisicao.toLowerCase());
  
    return isMatchingPatient && matchesSpecialty;
  });

  

  return (
    <div>
      <SideBar />
      <div className={styles.containerAdminNavBar}>

        <div className={styles.container}>
      
        {mostrarFormulario ? (
          <>
            {step === 1 && (
              <FormStep1
                requisicaoEdit={requisicaoEdit}
                setRequisicaoEdit={setRequisicaoEdit}
                handleNextStep={handleNextStep}
              />
            )}
            {step === 2 && (
              <FormStep2
                requisicaoEdit={requisicaoEdit}
                setRequisicaoEdit={setRequisicaoEdit}
                handlePrevStep={handlePrevStep}
                handleNextStep={handleNextStep}
              />
            )}
            {step === 3 && (
              <FormStep3
                requisicaoEdit={requisicaoEdit}
                setRequisicaoEdit={setRequisicaoEdit}
                handlePrevStep={handlePrevStep}
                handleEditSubmit={handleEditSubmit}
                pacientes={pacientes}
                preferenciais={preferenciais}
                especialidades={especialidades}
              />
            )}
          </>
        ) : (
          <>
           

       
            <h1 className={styles.titulo}>LISTA DE REQUISIÇÕES</h1>
    
            <div className={styles.conteudo}>
              <input
                type="text"
                placeholder="Pesquisar Especialidade"
                value={filtroRequisicao}
                onChange={(e) => setFiltroRequisicao(e.target.value)}
              />
              <br />
             </div>
             <div>
              <table className={styles.tabela}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>NOME COMPLETO</th>
                    <th>RG</th>
                    <th>CARTÃO SUS</th>
                    <th>DESCRICÃO DOS SINTOMAS</th>
                    <th>PREFERENCIAL</th>{" "}
                    <th>ESPECIALIDADE</th>
                    <th>RUA</th>
                    <th>BAIRRO</th>
                    <th>CEP</th>
                    <th>NÚMERO</th>
                    <th>COMPLEMENTO</th>
                    <th>ACÕES</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {filteredRequisicoes.map((requisicao) => (
                    <tr key={requisicao.id}>
                      <td>{requisicao.id}</td>
                      <td>{requisicao.nomeCompleto}</td>
                      <td>{requisicao.rg}</td>
                      <td>{requisicao.cartaoSUS}</td>
                      <td>{requisicao.descricaoDosSintomas}</td>
                      <td>{requisicao.preferencial.nome}</td>{" "}
                      <td>{requisicao.especialidade.nome}</td>
                      <td>{requisicao.endereco.rua}</td>
                      <td>{requisicao.endereco.bairro}</td>
                      <td>{requisicao.endereco.cep}</td>
                      <td>{requisicao.endereco.numero}</td>
                      <td>{requisicao.endereco.complemento}</td>
                      <td>
                      <div className={styles.botoesContainer}>
                          <button
                            className={`${styles.botaoRover} ${styles.azul}`}
                            onClick={() => handleEditar(requisicao.id)}
                          >
                            <BsPencil />
                          </button>
                          <button
                            className={`${styles.botaoRover} ${styles.vermelho}`}
                            onClick={() => handleExcluir(requisicao.id)}
                          >
                            <BsFillTrashFill />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
            </div>

            {loading ? (
              <p>Carregando...</p>
            ) : (
              <>
                {filteredRequisicoes.length === 0 ? (
                  <p>Não há requisicoes ainda.</p>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
      </div>
      </div>
      
    
     
      
    
  );
};

export default ListarRequisicao;
