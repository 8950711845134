import styles from '../styles/ListaPaciente.module.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBarAgente from '../menus/SideBar.Agente';
import axios from 'axios';
import Select from 'react-select';

function ListaPaciente() {
  const navegação = useNavigate();

  const [fichas, setFichas] = useState([]);
  const [pacientesSelecionados, setPacientesSelecionados] = useState([]);
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState(null);
  const [mesSelecionado, setMesSelecionado] = useState(null);
  const [especialidades, setEspecialidades] = useState([]);
  const [meses, setMeses] = useState([]);


  useEffect(() => {
    axios
      .get('https://tesw.tecnologiaemsoftwareweb.com.br/api/mes')
      .then((response) => setMeses(response.data))
      .catch((error) => console.log(error));
  }, []);

  // ... (other useEffect blocks)

  // Function to handle the selection of a month
  const handleMesSelect = (selectedOption) => {
    setMesSelecionado(selectedOption);
  };
  // Função para gerar um número aleatório entre min e max (inclusive)
  const generateRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Função para gerar um número de protocolo único
  const generateProtocolNumber = () => {
    return generateRandomNumber(10000, 99999);
  };





  let proximoNumeroFicha = 1;
  // Função para gerar um número de ficha único sequencial
  const generateFichaNumber = () => {
    // Verifica se atingiu o limite (1000)
    if (proximoNumeroFicha > 1000) {
      // Reinicia a contagem se atingiu o limite
      proximoNumeroFicha = 1;
    }

    // Retorna o próximo número de ficha e incrementa para a próxima chamada
    return proximoNumeroFicha++;
  };




  const handleGenerateListClick = () => {
    // Verificações para garantir que os campos necessários foram selecionados
    if (!mesSelecionado) {
      alert('Por favor, selecione um mês');
      return;
    }
    if (!especialidadeSelecionada) {
      alert('Por favor, selecione uma especialidade');
      return;
    }

    if (pacientesSelecionados.length === 0) {
      alert('Por favor, selecione pelo menos um paciente');
      return;
    }

    // Obter o primeiro paciente selecionado na lista
    const primeiroPacienteSelecionado = pacientesSelecionados[0];

    // Obter a ficha correspondente ao primeiro paciente selecionado
    const fichaCorrespondente = fichas.find(
      (ficha) =>
        ficha.paciente.id === primeiroPacienteSelecionado.value &&
        ficha.especialidade.id === especialidadeSelecionada.value
    );

    if (!fichaCorrespondente) {
      alert('Erro ao encontrar a ficha correspondente ao primeiro paciente selecionado');
      return;
    }

    // Mapeia os pacientes selecionados para criar a estrutura desejada no JSON
    const jsonData = {
      emails: pacientesSelecionados.map((paciente) => {
        const protocoloNumero = generateProtocolNumber();
        const fichaNumero = generateFichaNumber(proximoNumeroFicha);

        return {
          id: paciente.value,
          email: paciente.email,
          especialidade: especialidadeSelecionada ? especialidadeSelecionada.nome : '',
          titulo: fichaCorrespondente.titulo, // Usar o titulo da ficha correspondente
          mensagem: fichaCorrespondente.mensagem, // Usar a mensagem da ficha correspondente
          mes_id: mesSelecionado.value,
          protocoloNumero,
          fichaNumero,
          ficha_id: fichaCorrespondente.id,
          requisicao_id: fichaCorrespondente.requisicao.id,
        };
      }),
    };

    // Restante do código permanece inalterado
    const dataStr = JSON.stringify(jsonData, null, 2);
    const filePath = "C:\\Users\\Wanderson\\Downloads\\Back-end-Sicap\\helpers\\utils\\lista.json";

    axios
      .post('https://tesw.tecnologiaemsoftwareweb.com.br/api/salvar-json', { filePath, dataStr })
      .then(() => {
        navegação('/enviaremail');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Função para lidar com a seleção de uma especialidade
  const handleEspecialidadeSelect = (selectedOption) => {
    setEspecialidadeSelecionada(selectedOption);

  };

  // Função para lidar com a seleção de pacientes
  const handlePacientesSelect = (selectedOptions) => {
    setPacientesSelecionados(selectedOptions);
  };

  // useEffect para carregar dados do paciente quando o componente renderiza
  useEffect(() => {
    axios
      .get('https://tesw.tecnologiaemsoftwareweb.com.br/api/ficha')
      .then((response) => {
        setFichas(response.data);


      })
      .catch((error) => console.log(error));
  }, []);


  // useEffect para carregar dados da especialidade quando o componente renderiza
  useEffect(() => {
    axios
      .get('https://tesw.tecnologiaemsoftwareweb.com.br/api/especialidade')
      .then((response) => setEspecialidades(response.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <SideBarAgente />
      <div className={styles.containerAdminNavBar}>
        <div className={styles.container}>
          <div className={styles.formularioEdicao}>
            <main>
              <h2>Listar Pacientes para Envio</h2>
              <div className={styles.conteudo}>
                <div>
                  <label style={{ color: 'white' }}>Mês:</label>
                  <Select
                    options={meses.map((mes) => ({
                      value: mes.id,
                      label: mes.nome,
                      className: styles.blueOption,
                    }))}
                    value={mesSelecionado}
                    onChange={handleMesSelect}
                    className={styles.customSelect}
                    required
                  />
               
                  <label style={{ color: 'white' }}>Especialidade:</label>
                  <Select
                    options={especialidades.map((especialidade) => ({
                      value: especialidade.id,
                      label: especialidade.nome,
                      className: styles.blueOption,
                    }))}
                    value={especialidadeSelecionada}
                    onChange={handleEspecialidadeSelect}
                    className={styles.customSelect}
                    required
                  />
              
                  <label style={{ color: 'white' }}>Pacientes:</label>
                  <Select
                    isMulti
                    options={fichas
                      .filter((ficha) => ficha.especialidade.id === especialidadeSelecionada?.value)
                      .filter((ficha) => ficha.mes.id === mesSelecionado?.value)
                      .map((ficha) => ({
                        value: ficha.paciente.id,
                        label: `${ficha.paciente.nome} - ${ficha.paciente.email}`,
                        className: styles.blueOption,
                        email: ficha.paciente.email,
                      }))
                    }
                    value={pacientesSelecionados}
                    onChange={handlePacientesSelect}
                    className={styles.customSelect}
                    required
                  />
                </div>
              </div>
              <button onClick={handleGenerateListClick}>Listar e-mails</button>
            </main>
          </div>
        </div>
      </div>
    </>

  
  );
}

export default ListaPaciente;
