import React from "react";
import likendin from '../../img/Likendin.png'
import instragram from '../../img/instragram.png'
import youtube from '../../img/youtube.png'
import wathasp from '../../img/wathasp.png'
import styles from  '../menus/Footer.css'

const Footer = () => (
  <div className={styles.footer}>
  <div className="footer">
    <div className="sb__footer section__padding">
      <div className="sb__footer-links">
        <div className="sb__footer-links-div">
          <h4>Contato</h4>
            <p>(83)99407-5812</p>
            <p>sicap@gmail.com</p>
            <p>Segunda à sexta das 7H às 18H</p>
        </div>
        <div className="sb__footer-links-div">
          <h4>Informações</h4>
          <a href="/guiadousuario">
            <p>Guia Do Usuário</p>
          </a>

          <a href="/sobre">
            <p>Sobre</p>
          </a>

          <a href="/dicas">
            <p>Dicas</p>
          </a>
        </div>
        <div className="sb__footer-links-div">
          <h4>Links Importantes</h4>
          <a href="/login">
            <p>Minhas Requisições</p>
          </a>

          <a href="/lgpd">
            <p>Politica de Privacidade</p>
          </a>

          <a href="/login">
            <p>Entrar</p>
          </a>
        </div>
        <div className="sb__footer-links-div">
          <h4>Nossas Redes Sociais</h4>
          <div className="socialmedia">
            <p>
              <img src={likendin} alt="" />
            </p>
            <p>
              <img src={instragram} alt="" />
            </p>
            <p>
              <img src={youtube} alt="" />
            </p>
            <p>
              <img src={wathasp} alt="" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="sb__footer-below">
      <div className="sb__footer-copyright">
        <p>@{new Date().getFullYear()}  SICAP | Desenvolvido por TESW</p>
      </div>
      <div className="sb__footer-below-links">
      </div>
    </div>
  </div>
  </div>
);

export default Footer;
