import styles from '../styles/Dicas.module.css'
import NavBar from '../menus/NavBar';
import Footer from '../menus/Footer';
import { FaCalendarAlt, FaUsers, FaInfo, FaClipboardList } from 'react-icons/fa';



function Dicas() {
    return(
             <>
             <NavBar />
             <div className={styles.conteudo_pgn}>
            <div className={styles.conteudo_edit}>
            <h1>Dicas</h1></div></div>
            <section className={styles.conteudo_secundario}>
                <div className={styles.conteudo_texto}>
                    <p className={styles.texto}><strong>Dica 01</strong>Se for a sua primeira vez aqui usando o site, você precisará criar uma conta fornecendo algumas informações básicas, como nome, e-mail, telefone e senha. Se já tiver uma conta, basta fazer o login usando suas credenciais.</p>      
                    <p className={styles.texto}><strong>Dica 02</strong>Após fazer login, familiarize-se com as diferentes seções do site. Explore as opções disponíveis, como requisições, fichas, entre outros. Leia as descrições e instruções fornecidas para entender como cada função do site funciona.</p>      
                    <p className={styles.texto}><strong>Dica 03</strong>“Requisições”, utilize essa funcionalidade para marcar suas consultas de forma conveniente. Selecione a especialidade médica desejada e  confirme a marcação. </p>      
                    <p className={styles.texto}><strong>Dica 04</strong>Ao escrever sua requisição, dê o máximo de detalhes possíveis sobre os seus sintomas, para fazer a triagem.</p>      
                    <p className={styles.texto}><strong>Dica 05</strong>Não utilize o SICAP para enviar manifestações de ouvidoria, como denúncias, reclamações, elogios, entre outras.</p>      
                </div>   
            </section>
        <Footer />
    </>
    )
}
export default Dicas;