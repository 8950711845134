import FormLogin from '../form/FormLogin';
import styles from '../styles/Login.module.css';
import {  useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import logo3 from '../../img/imagememgif.gif'
import NavBar from '../menus/NavBar';
import Footer from '../menus/Footer';

function Login() {
    const [cadastroTipo, setCadastroTipo] = useState("paciente");
    const navigate = useNavigate();
    // eslint-disable-next-line
    const handleCadastroTipoChange = (tipo) => {
      setCadastroTipo(tipo);
    };
  
    const handleCadastroButtonClick = () => {
      if (cadastroTipo === "paciente") {
        navigate('/cadastro');
      } else {
        navigate('/cadastroagente');
      }
    };
  
    return (
      <div className={styles.container}>
             <NavBar />
      <div className={styles.conteudoLogin}>
        <div className={styles.conteudo1}>
        <h1 className={styles.conteudo_titulo}>SI<span>CAP</span></h1>
                    <h2 className={styles.conteudo_subTitulo}>Poupar seu tempo é a nossa prioridade!</h2><br/>
        <img src={logo3} alt='Imagem inicial' className={styles.image2}/>        
        </div>
        <div className={styles.formulario}>
          <h1 className={styles.conteudo_login}>LOGIN</h1>
          <FormLogin /><br/><br/>
          <hr />
          <h3>Ainda não tem acesso?</h3>
          <button className={styles.botaoCadastro} onClick={handleCadastroButtonClick}>
            Cadastre-se
          </button>
        </div>
      </div>
      <Footer />
      </div>
    );
  }
  
  export default Login;