import React from 'react';



const Step1 = ({ novaRequisicao, handleInputChange, handleNextStep}) => {
    return (
      <div>
  
        <h3>Dados Pessoais</h3>  
        <label>Nome Completo:</label>
        <input
          type="text"
          placeholder="Digite seu nome completo"
          name="nomeCompleto"
          value={novaRequisicao.nomeCompleto}
          onChange={handleInputChange}
          required
        />
  
        <label>RG:</label>
        <input
          type="text"
          placeholder="Digite seu RG (7 dígitos)"
          name="rg"
          value={novaRequisicao.rg}
          onChange={handleInputChange}
          required
        />
  
        <label>Cartão SUS:</label>
        <input
          type="text"
          placeholder="Digite seu Cartão SUS (15 dígitos)"
          name="cartaoSUS"
          value={novaRequisicao.cartaoSUS}
          onChange={handleInputChange}
          required
        />
  
        <label>Descrição dos Sintomas:</label>
        <input
          type="text"
          placeholder="Descreva seus sintomas"
          name="descricaoDosSintomas"
          value={novaRequisicao.descricaoDosSintomas}
          onChange={handleInputChange}
          required
        />
  
        <button type="button" onClick={handleNextStep}>
          Próximo
        </button>
      </div>
    );
  };
  
export default Step1;
