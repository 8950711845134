import React from 'react';
import styles from '../styles/ListarRequisicao.module.css';
const FormStep2 = ({ requisicaoEdit, setRequisicaoEdit, handlePrevStep, handleNextStep }) => {
  return (
    <div>
    <div className={styles.formularioEdicao}>
      <h3>Dados De Endereço</h3>
    
      <label htmlFor="Rua">Rua:</label>
            <input
              type="text"
              placeholder="Rua"
              value={requisicaoEdit?.endereco.rua || ""}
              onChange={(e) =>
                setRequisicaoEdit({
                  ...requisicaoEdit,
                  endereco: { ...requisicaoEdit.endereco, rua: e.target.value },
                })
              }
              required
            />
            <label htmlFor="Bairro">Bairro:</label>
            <input
              type="text"
              placeholder="Bairro"
              value={requisicaoEdit?.endereco.bairro || ""}
              onChange={(e) =>
                setRequisicaoEdit({
                  ...requisicaoEdit,
                  endereco: {
                    ...requisicaoEdit.endereco,
                    bairro: e.target.value,
                  },
                })
              }
              required
            />
            <label htmlFor="CEP">CEP:</label>
            <input
              type="text"
              placeholder="CEP"
              value={requisicaoEdit?.endereco.cep || ""}
              onChange={(e) =>
                setRequisicaoEdit({
                  ...requisicaoEdit,
                  endereco: { ...requisicaoEdit.endereco, cep: e.target.value },
                })
              }
              required
            />
            <label htmlFor="Número">Número:</label>
            <input
              type="text"
              placeholder="Número"
              value={requisicaoEdit?.endereco.numero || ""}
              onChange={(e) =>
                setRequisicaoEdit({
                  ...requisicaoEdit,
                  endereco: {
                    ...requisicaoEdit.endereco,
                    numero: e.target.value,
                  },
                })
              }
              required
            />
            
            <label htmlFor="Complemento">Complemento:</label>
            <input
              type="text"
              placeholder="Complemento"
              value={requisicaoEdit?.endereco.complemento || ""}
              onChange={(e) =>
                setRequisicaoEdit({
                  ...requisicaoEdit,
                  endereco: {
                    ...requisicaoEdit.endereco,
                    complemento: e.target.value,
                  },
                })
              }
            />
      <button onClick={handlePrevStep}>Anterior</button>
      <button onClick={handleNextStep}>Próximo</button>
    </div>
    </div>
   
  );
};





export default FormStep2;