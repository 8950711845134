import styles from '../styles/PacientesFichas.module.css';
import React, { useEffect, useState } from 'react';
import SideBarAgente from '../menus/SideBar.Agente';
import axios from 'axios';
import { BsFillTrashFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

// Importar os estilos de fonte para o pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function PacientesFichas() {
  const navegação = useNavigate();
  const [requisicoesFicha, setRequisicoesFicha] = useState([]);
  const [filtroRequisicaoFicha, setFiltroRequisicaoFicha] = useState('');
  const [modoEdicao, setModoEdicao] = useState(false);
  //const [mensagemErro, setMensagemErro] = useState('');
  // eslint-disable-next-line
  const [fichas, setFichas] = useState([]);
  // eslint-disable-next-line
  const [requisicoes, setRequisicoes] = useState([]);

  useEffect(() => {
    const accessTokenAgente = localStorage.getItem("accessTokenAgente");

    if (!accessTokenAgente) {
      // Redirecionar para a página de acesso negado
      navegação("/acessonegado");

      // Mostrar alerta ao usuário após um pequeno atraso
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500); // Ajuste o tempo de atraso conforme necessário
    }

  }, [navegação]);

  useEffect(() => {
    axios
      .get('https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicaoficha?_expand=requisicao&_expand=ficha')
      .then((response) => setRequisicoesFicha(response.data))
      .catch((error) => console.log(error));
  }, []);

  const removeRequisicaoFicha = (id) => {
    const confirmarExclusao = window.confirm("Tem certeza que deseja excluir esta Pacientes/ficha?");
    if (confirmarExclusao) {
      axios
        .delete(`https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicaoficha/${id}`)
        .then((response) => {
          const updatedRequisicoesFicha = requisicoesFicha.filter((reqFicha) => reqFicha.id !== id);
          setRequisicoesFicha(updatedRequisicoesFicha);
          alert("Paciente/ficha excluída com sucesso!");
          window.location.reload(); // Recarrega a página
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  useEffect(() => {
    axios
      .get('https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao')
      .then((response) => setRequisicoes(response.data))
      .catch((error) => console.log(error));
    axios
      .get('https://tesw.tecnologiaemsoftwareweb.com.br/api/ficha')
      .then((response) => setFichas(response.data))
      .catch((error) => console.log(error));
  }, []);

  const filtro_RequisicaoFicha = requisicoesFicha.filter(
    (reqFicha) =>
      reqFicha.requisicao &&
      ((reqFicha.requisicao.cartaoSUS || '').toLowerCase().includes(filtroRequisicaoFicha.toLowerCase()))
  );



  const gerarPdf = () => {
    const content = {
      content: [
        { text: 'PACIENTES AGENDADOS QUE RECEBERAM O E-MAIL', style: 'header' },
        {
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
              [
                'Nome Completo',
                'EMAIL',
                'RG',
                'Cartão SUS',
                'Descrição dos Sintomas',
                'Preferencial',
                'Especialidade',
                'Assinatura',
              ],
              ...filtro_RequisicaoFicha.map((reqFicha) => [
                reqFicha.requisicao?.nomeCompleto || '',
                reqFicha.requisicao?.paciente.email || '',
                reqFicha.requisicao?.rg || '',
                reqFicha.requisicao?.cartaoSUS || '',
                reqFicha.requisicao?.descricaoDosSintomas || '',
                reqFicha.requisicao?.preferencial.nome || '',
                reqFicha.ficha?.especialidade.nome || '',
                reqFicha.assinatura || '',
              ]),
            ],
          },
        },
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 10],
        },
      },
      pageOrientation: 'landscape', // Define a orientação da página como paisagem

    };

    pdfMake.createPdf(content).download('RequisicoesFichas.pdf');
  };

  return (
    <>
      <SideBarAgente />
      <div className={styles.conteudo_pgn}>
        <div className={styles.conteudo_edit}>
          <main>
            <h1>Requisições/Ficha</h1>
            <div className={styles.conteudo}>
              <input
                type="text"
                placeholder="Pesquisar por número do Cartão do SUS"
                value={filtroRequisicaoFicha}
                onChange={(e) => setFiltroRequisicaoFicha(e.target.value)}
              />

            </div>
            <div className={styles.buttonContainer}>
              <button onClick={gerarPdf} className={`${styles.button} button`}>
                Download
              </button>
            </div>
            <div>
              <table
                className={`${styles.tabela} ${modoEdicao ? styles.hidden : ''}`}
                style={{ display: modoEdicao ? 'none' : 'table' }}
              >
                <thead>
                  <tr>
                    <th>NOME COMPLETO</th>
                    <th>EMAIL</th>
                    <th>RG</th>
                    <th>CARTÃO SUS</th>
                    <th>DESCRIÇÃO DOS SINTOMAS</th>
                    <th>PREFERENCIAL</th>
                    <th>ESPECIALIDADE</th>
                    <th>EXCLUIR</th>
                  </tr>
                </thead>
                <tbody>
                  {filtro_RequisicaoFicha.map((reqFicha) => {
                    return (
                      <tr key={reqFicha.id}>
                        <td>{reqFicha.requisicao?.nomeCompleto || ''}</td>
                        <td>{reqFicha.requisicao?.paciente.email || ''}</td>
                        <td>{reqFicha.requisicao?.rg || ''}</td>
                        <td>{reqFicha.requisicao?.cartaoSUS || ''}</td>
                        <td>{reqFicha.requisicao?.descricaoDosSintomas || ''}</td>
                        <td>{reqFicha.requisicao?.preferencial.nome || ''}</td>
                        <td>{reqFicha.ficha?.especialidade.nome || ''}</td>
                        <td>
                          <button className={`${styles.botaoRover} ${styles.vermelho}`} onClick={() => removeRequisicaoFicha(reqFicha.id)}>
                            <i class="fas fa-trash-alt"></i> 
                            <BsFillTrashFill />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {modoEdicao ? (
                <div className={styles.editForm}>
                  <button onClick={() => setModoEdicao(false)}>Cancelar</button>
                </div>
              ) : null}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default PacientesFichas;
