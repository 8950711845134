import styles from '../styles/Home.module.css'
import logo2 from '../../img/pediatra.gif'
import NavBar from '../menus/NavBar';
import { Link } from 'react-router-dom'
import Footer from '../menus/Footer';
import { FaCalendarAlt, FaUsers, FaInfo, FaClipboardList } from 'react-icons/fa';



function Home() {
    return(
        <div className={styles.container}>
             <NavBar />
        <main className={styles.conteudo}>
            <section className={styles.sessao1}>
                <div className={styles.conteudo1}>
                <h1 className={styles.conteudo_titulo}>SI<span>CAP</span></h1>
                    <h2 className={styles.conteudo_subTitulo}>Sistema de Informações e Controle de Atendimento ao Público</h2>
                    <Link to="/sobre"><button className={styles.btn}>Saiba Mais</button></Link>
                </div>
                <div className={styles.roundImageContainer}>
                <img src={logo2} alt='Imagem inicial' className={styles.image2}/></div>
            </section>
            <section className={styles.conteudo_secundario}>
                <h1 className={styles.tittle_secundario}>Vantagens do seu uso</h1>
                <div className={styles.conteudo_texto}>
                    <p className={styles.texto}><FaClipboardList className={styles.icon} /><strong>Redução de Filas de Espera</strong>Ao oferecer um processo de agendamento online e eficaz, o SICAP contribui para a redução das filas de espera nas Unidades Básicas de Saúde (UBS), melhorando o fluxo de atendimentos.</p>      
                    <p className={styles.texto}><FaInfo className={styles.icon} /><strong>Acesso Rápido a Informações Relevantes</strong>O sistema fornece aos usuários acesso rápido a informações relevantes sobre procedimentos de saúde, esclarecendo dúvidas e contribuindo para uma melhor compreensão dos serviços oferecidos.</p>      
                    <p className={styles.texto}><FaCalendarAlt className={styles.icon} /><strong>Otimização do Agendamento</strong>O SICAP possibilita aos usuários agendar consultas de maneira eficiente, evitando conflitos de horários e permitindo que escolham especialidades de acordo com suas necessidades.</p>      
                    <p className={styles.texto}><FaUsers className={styles.icon} /><strong>Facilidade na Gestão de Atendimentos</strong>Para os agentes, o SICAP oferece ferramentas para uma gestão mais eficaz dos atendimentos, permitindo o acompanhamento detalhado das requisições, fichas, e demais informações relacionadas aos pacientes.</p>      

                </div>   
            </section>
        </main>
        <Footer />
        </div>
    )
}
export default Home;