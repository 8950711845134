import React from 'react';
import NavBar from '../menus/NavBar';
import Footer from '../menus/Footer';
import styles from '../menus/Layout.module.css';

function Layout({ children }) {
  return (
    <div className={styles.container}>
      <NavBar />
      <div className={styles.content}>
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
