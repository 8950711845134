import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FormCadastroAgente from '../form/FormCadastroAgente';
import SideBarAdmin from "../menus/SideBarAdmin";
import styles from '../styles/CadastroAgente.module.css';

function CadastroAgente() {
  const navegação = useNavigate();

  useEffect(() => {
    // Verifica se o usuário é administrador
    const isAdmin = localStorage.getItem("isAdmin");

    if (!isAdmin || isAdmin !== "true") {
      // Se não for um administrador, redireciona para uma página não autorizada
      alert("Você não tem permissão para acessar esta página.");
      navegação("/acessonegado"); // Substitua pela rota desejada
    }
  }, [navegação]);

  return (
    <>
    <SideBarAdmin />
      <div className={styles.content_container}>
        <div className={styles.image_section}>
          <div className={styles.image_content}>
          </div>
        </div>
        <div className={styles.form_section}>
          <h1 className={styles.title}>Cadastrar Agente</h1>
          <hr />
          <FormCadastroAgente />
        </div>
      </div>
      </>
  );
}

export default CadastroAgente;
