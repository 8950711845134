import React from 'react';
import styles from '../styles/ListarRequisicao.module.css';
const FormStep3 = ({ requisicaoEdit, setRequisicaoEdit, handlePrevStep, handleEditSubmit,pacientes,preferenciais,especialidades }) => {
  return (
      <div>
          <div className={styles.formularioEdicao}>
          <h3>Dados Da Requisição</h3>
          <label htmlFor="Paciente ID">Paciente ID:</label>
          <select
            value={requisicaoEdit?.paciente.id || ""}
            onChange={(e) =>
              setRequisicaoEdit({
                ...requisicaoEdit,
                paciente: { id: e.target.value },
              })
            }
            required
            disabled={requisicaoEdit?.pacienteId !== null}
          >
            <option value="">Selecione um paciente</option>
            {pacientes.map((paciente) => (
              <option key={paciente.id} value={paciente.id}>
                {`${paciente.id} - ${paciente.nome} (${paciente.email})`}
              </option>
            ))}
          </select>
          <label htmlFor="Preferencial ID">Preferencial:</label>
          <select
            value={requisicaoEdit?.preferencial.id || ""}
            onChange={(e) =>
              setRequisicaoEdit({
                ...requisicaoEdit,
                preferencial: { id: e.target.value },
              })
            }
            required
          >
            {preferenciais.map((preferencial) => (
              <option key={preferencial.id} value={preferencial.id}>
                {preferencial.nome}
              </option>
            ))}
          </select>
          <label htmlFor="Especialidade ID">Especialidade:</label>
          <select
            value={requisicaoEdit?.especialidade.id || ""}
            onChange={(e) =>
              setRequisicaoEdit({
                ...requisicaoEdit,
                especialidade: { id: e.target.value },
              })
            }
            required
          >
            {especialidades.map((especialidade) => (
              <option key={especialidade.id} value={especialidade.id}>
                {especialidade.nome}
              </option>
            ))}
          </select>
          <button onClick={handlePrevStep}>Anterior</button>
      <button onClick={handleEditSubmit}>Salvar</button>
        </div>
        </div>
        
    
  );
};

export default FormStep3;
