import { useNavigate } from "react-router-dom";
import styles from './SideBarAdmin.module.css';
import { RiAdminLine } from "react-icons/ri";
import { IoExitOutline } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiViewList } from "react-icons/ci";
import { Link } from 'react-router-dom';
import React, { useEffect } from "react";



function SideBar() {
  const navegação = useNavigate();



  useEffect(() => {
     // Verifica se o usuário é administrador
     const isAdmin = localStorage.getItem("isAdmin");

     if (!isAdmin || isAdmin !== "true") {
       // Se não for um administrador, redireciona para uma página não autorizada
       alert("Você não tem permissão para acessar esta página.");
       navegação("/acessonegado"); // Substitua pela rota desejada
     }
   }, [navegação]);
 
  

  const logout = () => {
    // Limpar os dados de autenticação (por exemplo, o token) do armazenamento local
    localStorage.removeItem("isAdmin");

    // Redirecionar para a página de login
    navegação("/login");
  };
    return(
        <div className={styles.SideBar}>
            <ul>

             <li className={styles.usuario2}>
        <Link style={{ color: 'inherit', cursor: 'default'}}>
        <span className={styles.icone}><RiAdminLine /></span>
        <span className={styles.titulo}>Olá, Admin</span>
        </Link>
           </li>
                <li className={styles.usuario}>
                    <Link to="/cadastroagente" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><IoIosAddCircleOutline /></span>
                        <span className={styles.titulo}> Cadastrar Agente</span>
                    </Link>
                </li>
                <li>
                    <Link to="/listaagentes" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><CiViewList/></span>
                        <span className={styles.titulo}>Agentes</span>
                    </Link>
                </li>
              
        
                <li className={styles.usuario3}>
                    <Link to="/login" style={{ color: 'inherit' }} onClick={logout}>
                        <span className={styles.icone}><IoExitOutline /></span>
                        <span className={styles.titulo}>Sair</span>
                    </Link>
                </li>
                
            </ul>
            
        </div>
    )
}

export default SideBar;

