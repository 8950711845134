import { useNavigate } from "react-router-dom";
import styles from './SideBar.module.css';
import { VscAccount  } from "react-icons/vsc";
import { FaUserPen } from "react-icons/fa6";
import { IoExitOutline } from "react-icons/io5";
import { LuFileSearch } from "react-icons/lu";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiViewList } from "react-icons/ci";
import { Link } from 'react-router-dom';
import React, { useEffect,useState } from "react";
import axios from "axios";


function SideBar() {
  const navegação = useNavigate();


  const [pacientes, setPacientes] = useState([]);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navegação("/acessonegado");
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500);
    } else {
      // Get user ID from the last log message in the login endpoint
      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/login', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        const logMessages = response.data.log_messages;
        if (logMessages && logMessages.length > 0) {
          const lastLogMessage = logMessages[logMessages.length - 1];
          const userIdFromLog = extractUserIdFromLog(lastLogMessage);
          setUserId(userIdFromLog); // Set user ID from the last log message
        }
      })
      .catch((error) => {
        console.error(error);
      });

      // Get all patients data
      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => setPacientes(response.data))
      .catch((error) => {
        // Handle request errors as needed
        console.error(error);
      });
    }
  }, [navegação]);

  const extractUserIdFromLog = (logMessage) => {
  const userIdRegex = /Usuário com ID (\d+) acessou/; // Regular expression to match the user ID pattern
  const match = logMessage.match(userIdRegex); // Match the regular expression with the log message

  // Log the original log message and the matched user ID for debugging
  console.log("Original Log Message:", logMessage);
  console.log("Matched User ID:", match ? match[1] : null);

  // Check if there is a match and return the extracted user ID, or return null if there is no match
  return match ? match[1] : null;
};

  

const filteredPatients = pacientes.filter((paciente) => {
  return paciente.id.toString() === userId;
});



// Acessar o nome do paciente logado, se houver um paciente filtrado
const nomeUsuarioLogado = filteredPatients.length > 0 ? filteredPatients[0].nome : '';

  

  const logout = () => {
    // Limpar os dados de autenticação (por exemplo, o token) do armazenamento local
    localStorage.removeItem("accessToken");

    // Redirecionar para a página de login
    navegação("/login");
  };
    return(
        <div className={styles.SideBar}>
            <ul>

             <li className={styles.usuario2}>
        <Link style={{ color: 'inherit', cursor: 'default'}}>
        <span className={styles.icone}><VscAccount /></span>
        <span className={styles.titulo}>Olá, {nomeUsuarioLogado}</span>
        </Link>
           </li>

            
                
               
                <li className={styles.usuario}>
                    <Link to="/pacientes" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><FaUserPen /></span>
                        <span className={styles.titulo}>Perfil Paciente</span>
                    </Link>
                </li>
                <li>
                    <Link to="/requisicao" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><IoIosAddCircleOutline /></span>
                        <span className={styles.titulo}>Nova Requisição</span>
                    </Link>
                </li>
                <li>
                    <Link to="/listarrequisicao" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><CiViewList /></span>
                        <span className={styles.titulo}>Minhas Requisicões</span>
                    </Link>
                </li>
                <li>
                    <Link to="/minhaficha" style={{ color: 'inherit' }}>
                        <span className={styles.icone}><LuFileSearch /></span>
                        <span className={styles.titulo}>Fichas Registradas</span>
                    </Link>
                </li>

                <li className={styles.usuario3}>
                    <Link to="/login" style={{ color: 'inherit' }} onClick={logout}>
                        <span className={styles.icone}><IoExitOutline /></span>
                        <span className={styles.titulo}>Sair</span>
                    </Link>
                </li>
                
            </ul>
            
        </div>
    )
}

export default SideBar;

