import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SideBarAgente from '../menus/SideBar.Agente';
import styles from '../styles/Agente.module.css';
import UserCardAgente from './UserCardAgente';
function Agentes() {
  const navegação = useNavigate();
  const [agentes, setAgentes] = useState([]);
  const [editAgente, setEditAgente] = useState(null);
  const [emailInvalido, setEmailInvalido] = useState(false);
  const [senhaInvalida, setSenhaInvalida] = useState(false);
  const [registroInvalido, setRegistroInvalido] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const accessTokenAgente = localStorage.getItem("accessTokenAgente");

    if (!accessTokenAgente) {
      navegação("/acessonegado");
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500);
    } else {
      // Get user ID from the last log message in the login endpoint
      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/loginagente', {
        headers: {
          Authorization: `Bearer ${accessTokenAgente}`
        }
      })
        .then((response) => {
          const logMessages = response.data.log_messages;
          if (logMessages && logMessages.length > 0) {
            const lastLogMessage = logMessages[logMessages.length - 1];
            const userIdFromLog = extractUserIdFromLog(lastLogMessage);
            setUserId(userIdFromLog); // Set user ID from the last log message
          }
        })
        .catch((error) => {
          console.error(error);
        });

      // Get all patients data
      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/agente', {
        headers: {
          Authorization: `Bearer ${accessTokenAgente}`
        }
      })
        .then((response) => setAgentes(response.data))
        .catch((error) => {
          // Handle request errors as needed
          console.error(error);
        });
    }
  }, [navegação]);

  const extractUserIdFromLog = (logMessage) => {
    const userIdRegex = /Usuário com ID (\d+) acessou/; // Regular expression to match the user ID pattern
    const match = logMessage.match(userIdRegex); // Match the regular expression with the log message

    // Log the original log message and the matched user ID for debugging
    console.log("Original Log Message:", logMessage);
    console.log("Matched User ID:", match ? match[1] : null);

    // Check if there is a match and return the extracted user ID, or return null if there is no match
    return match ? match[1] : null;
  };



  const agentesFiltrados = agentes.filter((agente) => {
    return agente.id.toString() === userId;
  });

  const editAgenteHandler = (agente) => {
    setEditAgente(agente);
    setMostrarFormulario(true);
  };

  const saveEditAgente = () => {
    // Verificar se os campos são válidos
    if (!editAgente.nome || !editAgente.email || !editAgente.senha || !editAgente.registroDeTrabalho) {
      alert("Todos os campos são obrigatórios!");
      return;
    }



    // Verificar se o nome de usuário tem entre 5 e 15 caracteres (letras ou números)
    const usernameRegex = /^[a-zA-Z0-9]{5,15}$/;
    if (!usernameRegex.test(editAgente.nome)) {
      alert("Nome de usuário inválido! O nome de usuário deve conter apenas letras e números e ter entre 5 e 15 caracteres.");
      return;
    }
    // Verificar senha
    const senhaRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    if (!senhaRegex.test(editAgente.senha)) {
      setSenhaInvalida(true);
      alert("Senha inválida! A senha deve ter pelo menos 8 caracteres, incluindo letras maiúsculas, minúsculas, números e caracteres especiais.");
      return;
    }

    // Verificar email
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(editAgente.email)) {
      setEmailInvalido(true);
      alert("Email inválido! Por favor, insira um endereço de email válido.");
      return;
    }

    // Verificar comprimento do registro de trabalho
    const registroDeTrabalho = parseInt(editAgente.registroDeTrabalho); // Converter para número inteiro
    if (isNaN(registroDeTrabalho) || registroDeTrabalho.toString().length !== 7) {
      setRegistroInvalido(true);
      alert('Número de registro de trabalho inválido. Deve conter no maximo 7 dígitos.');
      return;
    }

    // Atualizar o agente no servidor
    const confirmarEdicao = window.confirm("Tem certeza que deseja salvar as edições?");
    if (confirmarEdicao)
      axios.put(`https://tesw.tecnologiaemsoftwareweb.com.br/api/agente/${editAgente.id}`, editAgente)
        .then((response) => {
          // Atualizar a lista de agentes com o agente editado
          const updatedAgentes = agentes.map((agente) => (agente.id === response.data.id ? response.data : agente));
          setAgentes(updatedAgentes);
          setEditAgente(null); // Limpe o estado de edição
          setSucesso(true); // Exibir mensagem de sucesso
          setTimeout(() => {
            setSucesso(false);
            window.location.reload(); // Recarregar a página para refletir as alterações
          }, 1000);
        })
        .catch((error) => console.log(error));
  };

  const removeAgente = (id) => {
    const confirmarExclusao = window.confirm("Tem certeza que deseja excluir a sua conta?");
    if (confirmarExclusao)
      axios.delete(`https://tesw.tecnologiaemsoftwareweb.com.br/api/agente/${id}`)
        .then((response) => {
          // Remova o agente da lista de agentes
          const updatedAgentes = agentes.filter((agente) => agente.id !== id);
          setAgentes(updatedAgentes);
          // Mostra alerta e redireciona para a página de login
          alert("Conta excluída com sucesso");
          navegação('/login');
        })
        .catch((error) => console.log(error));
  };

  return (
    <>
      <SideBarAgente />
      <div className={styles.containerAdminNavBar}>
        {sucesso && <div className={styles.mensagemSucesso}>Atualizado com sucesso!</div>}
        <div className={styles.container}>
          <main>
            <h1>Minha Conta</h1>
            <br />
            {mostrarFormulario ? (
              <div className={styles.editForm}>
                <div className={styles.formGroup}>
                  <label>Nome:</label>
                  <input
                    type="text"
                    value={editAgente?.nome || ''}
                    onChange={(e) => setEditAgente({ ...editAgente, nome: e.target.value })}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Email:</label>
                  <input
                    type="email"
                    value={editAgente?.email || ''}
                    onChange={(e) => setEditAgente({ ...editAgente, email: e.target.value })}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Senha:</label>
                  <input
                    type="password"
                    value={editAgente?.senha || ''}
                    onChange={(e) => setEditAgente({ ...editAgente, senha: e.target.value })}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Registro de Trabalho:</label>
                  <input
                    type="text"
                    value={editAgente?.registroDeTrabalho || ''}
                    onChange={(e) => setEditAgente({ ...editAgente, registroDeTrabalho: e.target.value })}
                  />
                </div>
                <button onClick={saveEditAgente}>Salvar</button>
              </div>
            ) : (
              <div className={styles.cardContainer}>
                {agentesFiltrados.map((agente) => (
                  <UserCardAgente
                    key={agente.id}
                    agente={agente}
                    editAgenteHandler={editAgenteHandler}
                    removeAgente={removeAgente}
                  />
                ))}
              </div>
            )}
          </main>
        </div>
      </div>
    </>

  );
}

export default Agentes;
