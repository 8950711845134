import React from 'react';
import { Link } from 'react-router-dom';  // Certifique-se de importar Link corretamente
import styles from '../styles/Requisicao.module.css';

const Step3 = ({ novaRequisicao, handleInputChange, handlePrevStep, pacientes, preferenciais, especialidades, handleChangeCheckbox, concordaTermos, handleSubmit }) => {
    return (
      <div>
  
       <h3>Dados Da Requisição</h3> 
        <label>Paciente ID:</label>
        <select
          name="pacienteId"
          value={novaRequisicao.pacienteId}
          onChange={handleInputChange}
          required
          disabled={novaRequisicao.pacienteId !== null}
        >
          <option value="" disabled={novaRequisicao.pacienteId !== null}>
            Selecione o seu ID
          </option>
          {pacientes.map((paciente) => (
            <option key={paciente.id} value={paciente.id} disabled={paciente.id !== novaRequisicao.pacienteId}>
              {paciente.id}
            </option>
          ))}
        </select>
  
        <label>Preferencial:</label>
        <select name="preferencialId" value={novaRequisicao.preferencialId} onChange={handleInputChange} required>
          <option value="">Selecione uma preferência</option>
          {preferenciais.map((preferencial) => (
            <option key={preferencial.id} value={preferencial.id}>
              {preferencial.nome}
            </option>
          ))}
        </select>
  
        <label>Especialidade:</label>
        <select name="especialidadeId" value={novaRequisicao.especialidadeId} onChange={handleInputChange} required>
          <option value="">Selecione uma especialidade</option>
          {especialidades.map((especialidade) => (
            <option key={especialidade.id} value={especialidade.id}>
              {especialidade.nome}
            </option>
          ))}
        </select>
  
        <div className={styles.lgpd}>
          <label>
            <input
              type="checkbox"
              name="concordaTermos"
              checked={concordaTermos}
              onChange={handleChangeCheckbox}
              required
            />
            Li e aceito os&nbsp;<Link to="/LGPD" target="_blank">Termos e Condições.</Link>
          </label>
        </div>
  
        <button type="button" onClick={handlePrevStep}>
          Anterior
        </button>
        <button type="submit" onClick={handleSubmit}>
          Cadastrar
        </button>
      </div>
    );
  };
export default Step3;
