import NavBar from '../menus/NavBar';
import styles from  '../styles/Sobre.module.css'
import {FaGithub} from 'react-icons/fa'
import React, { useEffect} from "react";
import Footer from '../menus/Footer';




function Sobre() {
   

    return(
        <>
         <NavBar />
        <div className={styles.conteudo_pgn}>
        <div className={styles.conteudo_edit}>
            <h1>Sobre o SICAP</h1>
            <div className={styles.formGroup}>
            <p>   O SICAP (Sistema de Informações e Controle de Atendimento) é uma solução inovadora 
                desenvolvida por um grupo dedicado de estudantes do curso de 
                TSI (Tecnologia em Sistemas para Internet) no renomado IFPB (Instituto Federal da Paraíba) 
                Campus de Guarabira-PB. Este sistema foi cuidadosamente criado para resolver um problema 
                comum e significativo enfrentado pelas instituições de saúde - longas e cansativas filas 
                de espera. O SICAP visa transformar a experiência de atendimento, proporcionando uma 
                maneira eficiente e simplificada para pacientes e agentes de saúde.</p></div><br/>

            <div className={styles.formGroup}>
            <h2>Principais Recursos do SICAP</h2></div>
            <div className={styles.formGroup}>
            <p><strong>Cadastro e Gerenciamento de Fichas:</strong> Os usuários do tipo “Agente” têm a capacidade de 
                cadastrar novas fichas de atendimento.As informações essenciais, como título, mensagem, 
                especialidade, mês de marcação e dados do agente, são registradas de forma precisa e 
                organizada no sistema.</p></div>
            <div className={styles.formGroup}>
            <p><strong>Atendimento Simplificado:</strong> Os usuários, tanto os “Pacientes” quanto os “Agentes”, podem 
                acessar o sistema para obter informações, marcar consultas e solicitar esclarecimentos. O 
                processo é simplificado, eliminando a necessidade de esperas prolongadas e oferecendo 
                respostas rápidas às solicitações dos pacientes.</p></div>
            <div className={styles.formGroup}>
            <p><strong>Comunicação Eficiente:</strong> O SICAP facilita a comunicação entre pacientes e agentes.Os agentes
                podem responder às requisições dos pacientes, fornecendo detalhes sobre suas fichas, 
                esclarecendo dúvidas e fornecendo orientações sobre os procedimentos de atendimento.</p></div>
            <div className={styles.formGroup}>
            <p><strong>Especialidades Médicas:</strong> O sistema permite a categorização das fichas por especialidades 
                médicas. Isso garante que os pacientes sejam direcionados ao agente apropriado, 
                especializado na área correspondente às suas necessidades médicas.</p></div>
            <div className={styles.formGroup}>
            <p><strong>Agendamento Eficiente:</strong> O SICAP integra um sistema de agendamento inteligente, permitindo 
                que os pacientes marquem consultas e exames sem complicações. A marcação é feita de acordo 
                com a disponibilidade dos agentes, garantindo uma distribuição uniforme das consultas ao 
                longo do mês.</p></div><br/>
            
            <div className={styles.formGroup}>
            <h2>Impacto e Benefícios</h2></div>
            <div className={styles.formGroup}>
            <p><strong>Redução de Filas:</strong> Eliminação das longas filas de espera, reduzindo o tempo 
            de espera dos pacientes e melhorando sua experiência.</p></div>
            <div className={styles.formGroup}>
            <p><strong>Agilidade e Eficiência:</strong> Agiliza o processo de atendimento, permitindo que 
            os agentes respondam rapidamente às necessidades dos pacientes, resultando em um serviço mais 
            eficiente e satisfatório.</p></div>
            <div className={styles.formGroup}>
            <p><strong>Organização:</strong> Organiza as informações de maneira sistemática, garantindo 
            fácil acesso e recuperação de dados para consulta futura.</p></div>
            <div className={styles.formGroup}>
            <p><strong>Melhoria na Comunicação:</strong> Facilita a comunicação entre pacientes e agentes, 
            promovendo uma troca de informações clara e eficaz.</p></div>
            <div className={styles.formGroup}>
            <p><strong>Otimização de Recursos:</strong> Ajuda na gestão eficiente dos recursos disponíveis, 
            garantindo que os agentes sejam alocados de acordo com a demanda.</p></div>
        <div></div></div>
        <div className={styles.formGroup}>

            <h3 className={styles.textoSobre}>Repositórios do Projeto</h3>
            <FaGithub size="60px" color="white"/>
            
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <p><a href="https://github.com/WandersonCesar14/Sicap-Front" target="_blank" rel="noopener noreferrer">Front</a></p>
                    <hr />
                </div>
                <div>
                    <p><a href="https://github.com/joseMarcus/Sicap-Back" target="_blank" rel="noopener noreferrer">Back</a></p>
                    <hr />
                </div>
                </div>

        
        </div></div>
        <Footer />
        </>
    )
}

export default Sobre;