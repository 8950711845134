import React, { useEffect, useState } from "react";
import axios from "axios";
import SideBar from '../menus/SideBar';
import styles from '../styles/Requisicao.module.css';
import Step1 from "../form/Step1";
import Step2 from "../form/Step2";
import Step3 from "../form/Step3";
import { Link, useNavigate } from 'react-router-dom';



function Requisicao() {
  const navegação = useNavigate();
  const [concordaTermos, setConcordaTermos] = useState(false);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requisicoes, setRequisicoes] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  
  const handleChangeCheckbox = (e) => {
    const isChecked = e.target.checked;
    console.log("Checkbox checked:", isChecked);
    setConcordaTermos(isChecked);
  };

  const [novaRequisicao, setNovaRequisicao] = useState({
    nomeCompleto: "",
    rg: "",
    cartaoSUS: "",
    descricaoDosSintomas: "",
    preferencialId: null,
    especialidadeId: null,
    pacienteId: null,
    endereco: {
      rua: "",
      bairro: "",
      cep: "",
      numero: "",
      complemento: ""
    }
  });

  const [preferenciais, setPreferenciais] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [pacientes, setPacientes] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navegação("/acessonegado");
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500);
    } else {
      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/login', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          const logMessages = response.data.log_messages;
          if (logMessages && logMessages.length > 0) {
            const lastLogMessage = logMessages[logMessages.length - 1];
            const userIdFromLog = extractUserIdFromLog(lastLogMessage);
            setUserId(userIdFromLog);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          console.log("Requisicao:", response.data);
          setRequisicoes(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });

      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/preferencial')
        .then(response => {
          setPreferenciais(response.data);
        })
        .catch(error => {
          console.error(error);
        });

      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/especialidade')
        .then(response => {
          setEspecialidades(response.data);
        })
        .catch(error => {
          console.error(error);
        });

      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente')
        .then(response => {
          setPacientes(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [navegação]);

  useEffect(() => {
    if (userId) {
      setNovaRequisicao(prevState => ({
        ...prevState,
        pacienteId: userId
      }));
    }
  }, [userId]);

  const extractUserIdFromLog = (logMessage) => {
    const userIdRegex = /Usuário com ID (\d+) acessou/;
    const match = logMessage.match(userIdRegex);
    console.log("Original Log Message:", logMessage);
    console.log("Matched User ID:", match ? match[1] : null);
    return match ? match[1] : null;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNovaRequisicao(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEnderecoChange = (e) => {
    const { name, value } = e.target;
    setNovaRequisicao(prevState => ({
      ...prevState,
      endereco: {
        ...prevState.endereco,
        [name]: value
      }
    }));
  };


  const handleNextStep = () => {
    setCurrentStep((prevState) => prevState + 1);
  };
  
  const handlePrevStep = () => {
    setCurrentStep((prevState) => prevState - 1);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();


    // Verificar se o usuário concordou com os termos
    if (!concordaTermos) {
      alert("Você deve concordar com os termos e condições antes de prosseguir.");
      return;
    }

    // Validar se Rua, Número e Bairro não estão vazios
    if (!novaRequisicao.endereco.rua.trim() || !novaRequisicao.endereco.numero.trim() || !novaRequisicao.endereco.bairro.trim()) {
      alert("Por favor, preencha os campos de Rua, Número e Bairro.");
      return;
    }

    const descricaoSintomasRegex = /^[A-Za-zÀ-ÿ\sçÇ]+$/;
    if (
      !novaRequisicao.descricaoDosSintomas.trim() ||
      !descricaoSintomasRegex.test(novaRequisicao.descricaoDosSintomas)
    ) {
      alert(
        "Por favor, insira uma Descrição dos Sintomas válida contendo apenas letras"
      );
      return;
    }

      // Validar RG (11 dígitos com pontos e hífen)
  const rgRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

  if (!rgRegex.test(novaRequisicao.rg)) {
  alert("Por favor, insira um RG válido com 11 dígitos, no formato 000.000.000-00.");
  return;
  }


    // Validar Cartão SUS (15 digits)
    const cartaoSusRegex = /^\d{15}$/;
    if (!cartaoSusRegex.test(novaRequisicao.cartaoSUS)) {
      alert("Por favor, insira um número de Cartão SUS válido com 15 dígitos.");
      return;
    }

    // Validar CEP (8 digits)
    const cepRegex = /^\d{8}$/;
    if (!cepRegex.test(novaRequisicao.endereco.cep)) {
      alert("Por favor, insira um CEP válido com 8 dígitos.");
      return;
    }

    // Validar nome completo (apenas letras)
    const nomeRegex = /^[A-Za-z\s]+$/;
    if (!nomeRegex.test(novaRequisicao.nomeCompleto)) {
      alert("Por favor, insira um nome completo válido contendo apenas letras.");
      return;
    }


    const requisicaoParaEnviar = {
      ...novaRequisicao,
      preferencial: { id: novaRequisicao.preferencialId },
      especialidade: { id: novaRequisicao.especialidadeId },
      paciente: { id: novaRequisicao.pacienteId },
    };

    console.log("Requisição a ser enviada:", requisicaoParaEnviar);
    const confirmarRequisicao = window.confirm("Tem certeza que os dados da requisicão estão ok?");
    if (confirmarRequisicao)

      // Lógica para fazer a requisição para salvar a requisição
      axios.post('https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao', requisicaoParaEnviar)
        .then(response => {
          console.log(response.data);
          alert("Requisição cadastrada com sucesso!");
          navegação('/listarrequisicao');
          // Lógica adicional após o cadastro
        })
        .catch(error => {
          console.error(error);
          alert("Erro ao cadastrar requisição.");
        });

    // Lógica para fazer a requisição para salvar o endereço
    axios.post('https://tesw.tecnologiaemsoftwareweb.com.br/api/endereco', novaRequisicao.endereco)
      .then(response => {
        console.log(response.data);
        // Lógica adicional após o cadastro do endereço
      })
      .catch(error => {
        console.error(error);
        alert("Erro ao cadastrar endereço.");
      });
  };

  useEffect(() => {
    // Requisição para obter opções de preferencial
    axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/preferencial')
      .then(response => {
        setPreferenciais(response.data);
      })
      .catch(error => {
        console.error(error);
      });

    // Requisição para obter opções de especialidade
    axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/especialidade')
      .then(response => {
        setEspecialidades(response.data);
      })
      .catch(error => {
        console.error(error);
      });
    // Requisição para obter opções de especialidade
    axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente')
      .then(response => {
        setPacientes(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      <SideBar />
      <div className={styles.containerAdminNavBar}></div>
      <div className={styles.formularioEdicao}>
        {currentStep === 1 && (
          <Step1
          novaRequisicao={novaRequisicao}
          handleInputChange={handleInputChange}
          handleNextStep={handleNextStep}
        />
        )}

        {currentStep === 2 && (
          <Step2
          novaRequisicao={novaRequisicao}
          handleEnderecoChange={handleEnderecoChange}
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
          />
         
          
        )}

        {currentStep === 3 && (
          <Step3            
          novaRequisicao={novaRequisicao}
          handleInputChange={handleInputChange}
          handleChangeCheckbox={handleChangeCheckbox}
          handlePrevStep={handlePrevStep}
          preferenciais={preferenciais}
          especialidades={especialidades}
          pacientes={pacientes}
          concordaTermos={concordaTermos}
          handleSubmit={handleSubmit}
        />
        )}
      </div>
    </div>
  );
};

export default Requisicao;