import React from 'react';
import { BsPencil, BsFillTrashFill } from 'react-icons/bs';
import styles from '../styles/UserCard.module.css'; 



const UserCardAgente = ({ agente, editAgenteHandler, removeAgente }) => {
    return (
      <div className={styles.card}>
        <div>
      <h3>PERFIL</h3>
      <br />
    </div>
        <div>
          <strong>NOME:</strong> {agente.nome}
        </div>
        <div>
          <strong>EMAIL:</strong> {agente.email}
        </div>
        <div>
          <strong>SENHA:</strong> {agente.senha}
        </div>
        <div>
          <strong>REGISTRO DE TRABALHO:</strong> {agente.registroDeTrabalho}
        </div>
        <div>
          <button onClick={() => editAgenteHandler(agente)}>
            <BsPencil />
          </button>
          <button className={styles.deleteButton} onClick={() => removeAgente(agente.id)}>
          <BsFillTrashFill />
        </button>
        </div>
      </div>
    );
  };
  
  export default UserCardAgente;