import React, { useState } from 'react';
import styles from '../styles/FormCadastro.module.css';
import Button from '../menus/Button';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function FormCadastroAgente() {
  const [accessTokenAgente, setAccessTokenAgente] = useState('');
  const [novoAgente, setNovoAgente] = useState({
    nome: '',
    email: '',
    senha: '',
    registroDeTrabalho: '', // Defina o tipo de dados correto para o registro de trabalho (string em vez de número)
  });
  const navigate = useNavigate();
  const [concordaTermos, setConcordaTermos] = useState(false); // Novo estado para rastrear o consentimento
  const handleChangeCheckbox = (e) => {
    setConcordaTermos(e.target.checked);
  };

  const validarNome = (nome) => {
    const regexNome = /^[a-zA-Z0-6_]{5,21}$/; // Assume que o nome de usuário deve ter pelo menos 5 letras e pode incluir underscores
    return regexNome.test(nome);
  }; 

  const validarSenha = (senha) => {
    const regexSenha = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
    return regexSenha.test(senha);
  };

  const validarEmail = (email) => {
    const regexEmail = /^\S+@\S+\.\S+$/;
    return regexEmail.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNovoAgente((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { registroDeTrabalho } = novoAgente;
     // Verificar se o usuário concordou com os termos
     if (!concordaTermos) {
      alert("Você deve concordar com os termos e condições antes de prosseguir.");
      return;
    }



    if (!validarNome(novoAgente.nome)) {
      alert("O nome de usuário deve ter entre 5 e 21 caracteres alfanuméricos ou underscores.");
      return;
    }

    // Verificar o comprimento do registro de trabalho
    if (registroDeTrabalho.length !== 7) {
      alert('Número de registro de trabalho inválido. Deve conter no maximo 7 dígitos.');
      return;
    }

    // Validar senha
    if (!validarSenha(novoAgente.senha)) {
      alert("A senha deve ter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial.");
      return;
    }

    // Validar email
    if (!validarEmail(novoAgente.email)) {
      alert("Por favor, insira um endereço de email válido.");
      return;
    }

    const agente = { ...novoAgente };
    cadastrarAgente(agente);
  };

  const cadastrarAgente = (agente) => {
    axios
      .post('https://tesw.tecnologiaemsoftwareweb.com.br/api/agente', agente)
      .then((response) => {
        console.log(response.data);
        setAccessTokenAgente(response.data.access_tokenAgente);
        localStorage.setItem('accessTokenAgente', response.data.access_tokenAgente);
        alert('Cadastro de agente feito com sucesso');
        navigate('/listaagentes');
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  };

  return (
    <div className={styles.formGroup}>
      <form className={styles.content_form} onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nome"
          name="nome"
          value={novoAgente.nome}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={novoAgente.email}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          placeholder="Senha"
          name="senha"
          value={novoAgente.senha}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          placeholder="Registro de Trabalho"
          name="registroDeTrabalho"
          value={novoAgente.registroDeTrabalho}
          onChange={handleChange}
          required
        />
        <label>
          <input
            type="checkbox"
            name="concordaTermos"
            checked={concordaTermos}
            onChange={handleChangeCheckbox}
            required
          />
          Li e aceito os&nbsp;<Link to='/lgpd' target='_blank'>Termos e Condições.</Link>
        </label>

        <Button type="submit" text="Cadastrar"></Button>
      </form>
    </div>
  );
}

export default FormCadastroAgente;
