import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SideBar from '../menus/SideBar';
import styles from '../styles/Pacientes.module.css';
import UserProfileCard from './UserProfileCard';
function Pacientes() {
  const navigate = useNavigate();
  const [pacientes, setPacientes] = useState([]);
  const [sucesso, setSucesso] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [userId, setUserId] = useState(null);
  // eslint-disable-next-line
  const [editPacienteId, setEditPacienteId] = useState(null);
  const [editPacienteDados, setEditPacienteDados] = useState({
    nome: "",
    email: "",
    telefone: ""
  });



  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navigate("/acessonegado");
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500);
    } else {
      // Get user ID from the last log message in the login endpoint
      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/login', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          const logMessages = response.data.log_messages;
          if (logMessages && logMessages.length > 0) {
            const lastLogMessage = logMessages[logMessages.length - 1];
            const userIdFromLog = extractUserIdFromLog(lastLogMessage);
            setUserId(userIdFromLog); // Set user ID from the last log message
          }
        })
        .catch((error) => {
          console.error(error);
        });

      // Get all patients data
      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => setPacientes(response.data))
        .catch((error) => {
          // Handle request errors as needed
          console.error(error);
        });
    }
  }, [navigate]);

  const extractUserIdFromLog = (logMessage) => {
    const userIdRegex = /Usuário com ID (\d+) acessou/; // Regular expression to match the user ID pattern
    const match = logMessage.match(userIdRegex); // Match the regular expression with the log message

    // Log the original log message and the matched user ID for debugging
    console.log("Original Log Message:", logMessage);
    console.log("Matched User ID:", match ? match[1] : null);

    // Check if there is a match and return the extracted user ID, or return null if there is no match
    return match ? match[1] : null;
  };



  const filteredPatients = pacientes.filter((paciente) => {
    return paciente && paciente.id && paciente.id.toString() === userId;
  });



  const removePaciente = (id) => {
    const confirmarExclusao = window.confirm("Tem certeza que deseja excluir a sua conta?");
    if (confirmarExclusao)
      axios.delete(`https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente/${id}`)
        .then((response) => {
          const updatedPacientes = pacientes.filter((paciente) => paciente.id !== id);
          setPacientes(updatedPacientes);
          // Mostra alerta e redireciona para a página de login
          alert("Conta excluída com sucesso");
          navigate('/login');
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const editPaciente = (paciente) => {
    setEditPacienteDados(paciente);
    setEditPacienteId(paciente.id);
    setMostrarFormulario(true);
  };




  const saveEditPaciente = () => {
    const confirmarEdicao = window.confirm("Tem certeza que deseja salvar as edições?");
    if (confirmarEdicao)
      // Verificar se os campos são válidos
      if (!editPacienteDados.nome || !editPacienteDados.email || !editPacienteDados.telefone) {
        alert("Todos os campos são obrigatórios!");
        return;
      }




    // Verificar nome de usuário
    const usernameRegex = /^[a-zA-Z0-9]{5,15}$/;
    if (!usernameRegex.test(editPacienteDados.nome)) {
      alert("Nome de usuário inválido! O nome de usuário deve conter apenas letras e números e ter entre 5 e 15 caracteres.");
      return;
    }

    // Verificar email
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(editPacienteDados.email)) {
      alert("Email inválido! Por favor, insira um endereço de email válido.");
      return;
    }

    // Verificar número de telefone
    const telefoneRegex = /^\d{11}$/; // Assume que o número de telefone deve ter 10 dígitos
    if (!telefoneRegex.test(editPacienteDados.telefone)) {
      alert("Número de telefone inválido! Por favor, insira um número de telefone válido.");
      return;
    }
    const { id, ...pacienteData } = editPacienteDados;
    axios.put(`https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente/${id}`, pacienteData)
      .then((response) => {
        const updatedPacientes = filteredPatients.map((paciente) => {
          if (paciente.id === id) {
            return response.data;
          }
          return paciente;
        });
        setPacientes(updatedPacientes);
        setEditPacienteId(null);
        // Limpar os dados de edição após a edição bem-sucedida
        setEditPacienteDados({
          nome: "",
          email: "",
          telefone: ""
          // Outros campos específicos de pacientes
        });
      })
      .catch((error) => {
        console.log(error);
      });
    setSucesso(true); // Exibir mensagem de sucesso
    setTimeout(() => {
      setSucesso(false);
      window.location.reload(); // Recarregar a página para refletir as alterações
    }, 500);
  };


  return (
    <>
      <SideBar />
      <div className={styles.containerAdminNavBar}>
        {sucesso && <div className={styles.mensagemSucesso}>Atualizado com sucesso!</div>}
        <div className={styles.container}>
          <main>
            <h1>Minha Conta</h1>
            <br />
            {mostrarFormulario ? (
              // Formulário de edição do paciente aqui
              <div className={styles.editForm}>
                <div className={styles.formGroup}>
                  <label>Nome:</label>
                  <input
                    type="text"
                    placeholder="Nome"
                    value={editPacienteDados.nome}
                    onChange={(e) =>
                      setEditPacienteDados({ ...editPacienteDados, nome: e.target.value })
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Email:</label>
                  <input
                    type="email"
                    placeholder="Email"
                    value={editPacienteDados.email}
                    onChange={(e) =>
                      setEditPacienteDados({ ...editPacienteDados, email: e.target.value })
                    }
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Telefone:</label>
                  <input
                    type="text"
                    placeholder="Telefone"
                    value={editPacienteDados.telefone}
                    onChange={(e) =>
                      setEditPacienteDados({ ...editPacienteDados, telefone: e.target.value })
                    }
                  />
                </div>
                <button onClick={saveEditPaciente}>Salvar</button>
              </div>
            ) : (
              <div className={styles.cardContainer}>
                {filteredPatients.map((paciente) => (
                  <UserProfileCard
                    key={paciente.id}
                    paciente={paciente}
                    editPaciente={editPaciente}
                    removePaciente={removePaciente}
                  />
                ))}
              </div>
            )}
          </main>
        </div>
      </div>
    </>
  );


}

export default Pacientes;
