import SideBarAgente from "../menus/SideBar.Agente";
import styles from '../styles/Ficha.module.css'
import axios from "axios";
import { BsPencil, BsFillTrashFill, BsPlus } from "react-icons/bs"
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";



function Ficha() {
  const navegação = useNavigate();
  const [fichas, setFichas] = useState([])
  const [editFichaId, setEditFichaId] = useState(null);
  const [editFichasDados, setEditFichasDados] = useState({});
  const [filtroFicha, setFiltroFicha] = useState("");
  const [mesId, setMesId] = useState(null);
  const [mes, setMes] = useState([]);
  const [especialidadeId, setEspecialidadeId] = useState(null);
  const [especialidades, setEspecialidades] = useState([]);
  const [agenteId, setAgenteId] = useState(null);
  const [agentes, setAgentes] = useState([]);
  const [pacienteId, setPacienteId] = useState(null);
  const [pacientes, setPacientes] = useState([]);
  const [requisicaoId, setRequisicaoId] = useState(null);
  const [requisicaos, setRequisicaos] = useState([]);
  const [novaFicha, setNovaFicha] = useState({
    titulo: "",
    mensagem: "",
    mes: { id: null },
    especialidade: { id: null },
    agente: { id: null },
    paciente: { id: null },
    requisicao: { id: null }
  });
  const [modoEdicao, setModoEdicao] = useState(false);
  const [mensagemErro, setMensagemErro] = useState("");

  useEffect(() => {
    const accessTokenAgente = localStorage.getItem("accessTokenAgente");

    if (!accessTokenAgente) {
      // Redirecionar para a página de acesso negado
      navegação("/acessonegado");

      // Mostrar alerta ao usuário após um pequeno atraso
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500); // Ajuste o tempo de atraso conforme necessário
    }

  }, [navegação]);

  const cadastrarFicha = () => {
    novaFicha.mes = { id: mesId };
    novaFicha.especialidade = { id: especialidadeId };
    novaFicha.agente = { id: agenteId };
    novaFicha.paciente = { id: pacienteId };
    novaFicha.requisicao = { id: requisicaoId };

    axios
      .post("https://tesw.tecnologiaemsoftwareweb.com.br/api/ficha", novaFicha)
      .then((response) => {
        setModoEdicao(false);
        setTimeout(() => {
          window.location.reload();
          alert("Ficha criada com sucesso!");
        }, 1000); // 1000 milissegundos = 1 segundos
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          setMensagemErro(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    axios
      .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/mes")
      .then((response) => setMes(response.data))
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    axios
      .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/especialidade")
      .then((response) => setEspecialidades(response.data))
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    axios
      .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/agente")
      .then((response) => setAgentes(response.data))
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    axios
      .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente")
      .then((response) => setPacientes(response.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/ficha')
      .then((response) => setFichas(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao')
      .then((response) => setRequisicaos(response.data))
      .catch((err) => console.log(err));
  }, []);
  const removeFicha = (id) => {
    const confirmarExclusao = window.confirm("Tem certeza que deseja excluir esta Ficha?");
    if (confirmarExclusao)
      axios.delete(`https://tesw.tecnologiaemsoftwareweb.com.br/api/ficha/${id}`)
        .then((response) => {
          const AttListaFicha = fichas.filter((ficha) => ficha.id !== id);
          alert("Ficha excluída com sucesso!");
          setFichas(AttListaFicha);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const editFicha = (id) => {
    axios.get(`https://tesw.tecnologiaemsoftwareweb.com.br/api/ficha/${id}`)
      .then((response) => {
        setEditFichasDados(response.data);
        setEditFichaId(id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveEditFicha = () => {
    axios.put(`https://tesw.tecnologiaemsoftwareweb.com.br/api/ficha/${editFichaId}`, editFichasDados)
      .then((response) => {
        const updatedFichas = fichas.map((ficha) => {
          if (ficha.id === editFichaId) {
            return response.data;
          }
          return ficha;
        });
        setFichas(updatedFichas);
        setEditFichaId(null);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filtro_Ficha = fichas.filter((ficha) => {
    const pacienteIdMatches = ficha.requisicao && ficha.requisicao.id.toString().includes(filtroFicha);

    return pacienteIdMatches;

  }
  );

  const adicionarFicha = () => {
    setModoEdicao(true);
    setMensagemErro("");
  };

  return (
    <>
      <SideBarAgente />
      <div className={styles.containerAdminNavBar}>
        <div className={styles.container}>
          <main>
            <h1>Fichas</h1>
            <div className={styles.conteudo}>
              <input
                type="text"
                placeholder="Pesquisar pelo ID da Requisição"
                value={filtroFicha}
                onChange={(e) => setFiltroFicha(e.target.value)}
              /></div>
            <div className={styles.conteudo2}>
              <button onClick={adicionarFicha} className={styles.icone}>
                <BsPlus />
              </button></div>


            {editFichaId ? (
              <div className={styles.editForm}>
                <form onSubmit={saveEditFicha}>

                  <div className={styles.formGroup}>
                    <label style={{ color: 'white' }}>Título:</label>
                    <input
                      type="text"
                      value={editFichasDados.titulo}
                      onChange={(e) => setEditFichasDados({ ...editFichasDados, titulo: e.target.value })}
                      required
                    /></div>
                  <div className={styles.formGroup}>
                    <label style={{ color: 'white' }}>Mensagem:</label>
                    <textarea
                      type="text"
                      value={editFichasDados.mensagem}
                      onChange={(e) => setEditFichasDados({ ...editFichasDados, mensagem: e.target.value })}
                      required
                    /></div>
                  <div className={styles.formGroup}>
                    <label style={{ color: 'white' }}>Paciente:</label>
                    <select
                      value={editFichasDados.paciente.id}
                      onChange={(e) =>
                        setEditFichasDados({
                          ...editFichasDados,
                          paciente: { id: e.target.value }
                        })
                      }
                    >
                      <option value="">Selecione o Id do paciente</option>
                      {pacientes.map((paciente) => (
                        <option key={paciente.id} value={paciente.id}>
                          {`${paciente.id} - ${paciente.nome} (${paciente.email})`}

                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={styles.formGroup}>
                    <label style={{ color: 'white' }}>Requisicão:</label>
                    <select
                      value={editFichasDados.requisicao.id}
                      onChange={(e) =>
                        setEditFichasDados({
                          ...editFichasDados,
                          requisicao: { id: e.target.value }
                        })
                      }
                    >
                      <option value="">Selecione o Id da requisicão</option>
                      {requisicaos.map((requisicao) => (
                        <option key={requisicao.id} value={requisicao.id}>
                          {`${requisicao.id} - PacienteID: ${requisicao.paciente.id} - ${requisicao.nomeCompleto} (${requisicao.especialidade.nome})`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={styles.formGroup}>
                    <label style={{ color: 'white' }}>Mes:</label>
                    <select
                      value={editFichasDados.mes.id}
                      onChange={(e) =>
                        setEditFichasDados({
                          ...editFichasDados,
                          mes: { id: e.target.value }
                        })
                      }
                    >
                      <option value="">Selecione um mes</option>
                      {mes.map((mes) => (
                        <option key={mes.id} value={mes.id}>
                          {mes.nome}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label style={{ color: 'white' }}>Especialidade:</label>
                    <select
                      value={editFichasDados.especialidade.id}
                      onChange={(e) =>
                        setEditFichasDados({
                          ...editFichasDados,
                          especialidade: { id: e.target.value }
                        })
                      }
                    >
                      <option value="">Selecione uma especialidade</option>
                      {especialidades.map((especialidade) => (
                        <option key={especialidade.id} value={especialidade.id}>
                          {especialidade.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={styles.formGroup}>
                    <label style={{ color: 'white' }}>Agente:</label>
                    <select
                      value={editFichasDados.agente.id}
                      onChange={(e) =>
                        setEditFichasDados({
                          ...editFichasDados,
                          agente: { id: e.target.value }
                        })
                      }
                    >
                      <option value="">Selecione um agente</option>
                      {agentes.map((agente) => (
                        <option key={agente.id} value={agente.id}>
                          {agente.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button className={styles.recuoBotao} type="submit">Salvar</button>
                </form><br />
              </div>
            ) : (
              <div>
                <table className={`${styles.tabela} ${modoEdicao ? styles.hidden : ""}`} style={{ display: modoEdicao ? "none" : "tabela" }}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>TÍTULO</th>
                      <th>MENSAGEM</th>
                      <th>PACIENTE ID</th>
                      <th>REQUISICÃO ID</th>
                      <th>MES</th>
                      <th>ESPECIALIDADE</th>
                      <th>AGENTE</th>
                      <th>ACÕES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtro_Ficha.map((ficha) => {
                      return (
                        <tr key={ficha.id}>
                          <td>{ficha.id}</td>
                          <td>{ficha.titulo}</td>
                          <td>{ficha.mensagem}</td>
                          <td>{ficha.paciente ? ficha.paciente.id : ""}</td>
                          <td>{ficha.requisicao ? ficha.requisicao.id : ""}</td>
                          <td>{ficha.mes ? ficha.mes.nome : ""}</td>
                          <td>{ficha.especialidade ? ficha.especialidade.nome : ""}</td>
                          <td>{ficha.agente ? ficha.agente.nome : ""}</td>
                          <td>
                            <div className={styles.botoesContainer}>
                              <button className={`${styles.botaoRover} ${styles.azul}`} onClick={() => editFicha(ficha.id)}>
                                <BsPencil />
                              </button>
                              <button className={`${styles.botaoRover} ${styles.vermelho}`} onClick={() => removeFicha(ficha.id)}>
                                <BsFillTrashFill />
                              </button>
                            </div></td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table><br />
                {modoEdicao ? (
                  <div className={styles.editForm}>
                    <form onSubmit={cadastrarFicha}>
                      <div className={styles.formGroup}>
                        <label style={{ color: 'white' }}>Título:</label>
                        <input
                          type="text"
                          value={novaFicha.titulo}
                          onChange={(e) => setNovaFicha({ ...novaFicha, titulo: e.target.value })}
                          required
                        />
                      </div>

                      <div className={styles.formGroup}>
                        <label style={{ color: 'white' }}>Mensagem:</label>
                        <textarea
                          type="text"
                          value={novaFicha.mensagem}
                          onChange={(e) => setNovaFicha({ ...novaFicha, mensagem: e.target.value })}
                          required
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label style={{ color: 'white' }}>Paciente:</label>
                        <select
                          value={pacienteId}
                          onChange={(e) => setPacienteId(e.target.value)}
                          required
                        >
                          <option value="">Selecione o ID do paciente</option>
                          {pacientes.map((paciente) => (
                            <option key={paciente.id} value={paciente.id}>
                              {`${paciente.id} - ${paciente.nome} (${paciente.email})`}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={styles.formGroup}>
                        <label style={{ color: 'white' }}>Requisicão:</label>
                        <select
                          value={requisicaoId}
                          onChange={(e) => setRequisicaoId(e.target.value)}
                          required
                        >
                          <option value="">Selecione o ID da requisicão</option>
                          {requisicaos.map((requisicao) => (
                            <option key={requisicao.id} value={requisicao.id}>
                              {`${requisicao.id} - PacienteID: ${requisicao.paciente.id} - ${requisicao.nomeCompleto} (${requisicao.especialidade.nome})`}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={styles.formGroup}>
                        <label style={{ color: 'white' }}>Mes:</label>
                        <select
                          value={mesId}
                          onChange={(e) => setMesId(e.target.value)}
                          required
                        >
                          <option value="">Selecione um mes</option>
                          {mes.map((mes) => (
                            <option key={mes.id} value={mes.id}>
                              {mes.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={styles.formGroup}>
                        <label style={{ color: 'white' }}>Especialidade:</label>
                        <select
                          value={especialidadeId}
                          onChange={(e) => setEspecialidadeId(e.target.value)}
                          required
                        >
                          <option value="">Selecione uma especialidade</option>
                          {especialidades.map((especialidade) => (
                            <option key={especialidade.id} value={especialidade.id}>
                              {especialidade.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={styles.formGroup}>
                        <label style={{ color: 'white' }}>Agente:</label>
                        <select
                          value={agenteId}
                          onChange={(e) => setAgenteId(e.target.value)}
                          required
                        >
                          <option value="">Selecione um Agente</option>
                          {agentes.map((agente) => (
                            <option key={agente.id} value={agente.id}>
                              {agente.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                      <button className={styles.recuoBotao} type="submit">Cadastrar</button>
                      {mensagemErro && <p>{mensagemErro}</p>}
                    </form><br />
                  </div>

                ) : null}
              </div>
            )}
          </main>
        </div>
      </div>
    </>
  )
}

export default Ficha;