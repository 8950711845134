import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cadastro from './componentes/pages/Cadastro';
import CadastroAgente from './componentes/pages/CadastroAgente';
import Sobre from './componentes/pages/Sobre';
import Home from './componentes/pages/Home';
import Ficha from './componentes/pages/Ficha';
import Requisicao from './componentes/pages/Requisicao';
import RequisicaoAgente from './componentes/pages/RequisicaoAgente';
import ListarRequisicao from './componentes/pages/ListarRequisicao';
import MinhaFicha from './componentes/pages/MinhaFicha';
import Agentes from './componentes/pages/Agentes';
import Pacientes from './componentes/pages/Pacientes';
import Login from './componentes/pages/Login';
import ListarAgentes from './componentes/pages/listarAgentes';
import ListaPaciente from './componentes/pages/ListaPaciente';
import EnviarEmail from './componentes/pages/EnviarEmail';
import PacientesFichas from './componentes/pages/PacientesFichas';
import AcessoNegado from './componentes/pages/AcessoNegado';
import LGPD from './componentes/pages/LGPD';
import Dicas from './componentes/pages/Dicas';
import GuiaDoUsuario from './componentes/pages/GuiaDoUsuario';



function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cadastro" element={<Cadastro />} />
          <Route path="/cadastroagente" element={<CadastroAgente />} />
          <Route path="/sobre" element={<Sobre />} />
          <Route path="/lgpd" element={<LGPD />} />
          <Route path="/dicas" element={<Dicas />} />
          <Route path="/guiadousuario" element={<GuiaDoUsuario />} />
          <Route path="/pacientes" element={<Pacientes />} />
          <Route path="/requisicao" element={<Requisicao />} />
          <Route path="/requisicaoagente" element={<RequisicaoAgente />} />
          <Route path="/listarRequisicao" element={<ListarRequisicao />} />
          <Route path="/minhaficha" element={<MinhaFicha />} />
          <Route path="/ficha" element={<Ficha />} />
          <Route path="/agente" element={<Agentes />} />
          <Route path="/login" element={<Login />} />
          <Route path="/listaagentes" element={<ListarAgentes />} />
          <Route path="/listapaciente" element={<ListaPaciente />} />
          <Route path="/enviaremail" element={<EnviarEmail />} />
          <Route path="/pacientesfichas" element={<PacientesFichas />} />
          <Route path="/acessonegado" element={<AcessoNegado />} />
        </Routes>
      </Router>
    </>
  );
}


export default App;