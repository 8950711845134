import NavBar from '../menus/NavBar';
import styles from '../styles/Sobre.module.css'
import Footer from '../menus/Footer';
import FormCadastro from '../../img/FormCadastro.png'
import Login from '../../img/Login.png'
import Cadastro from '../../img/Cadastro.png'
import Perfil from '../../img/Perfil.png'
import requisicao from '../../img/requisicao.png'
import DadosPessoais from '../../img/DadosPessoais.png'
import DadosEndereco from '../../img/DadosEndereco.png'
import DadosRequisicao from '../../img/DadosRequisicao.png'
import Requisicao3 from '../../img/Requisicao3.png'





function GuiaDoUsuario() {


    return (
        <>
            <NavBar />
            <div className={styles.conteudo_pgn}>
                <div className={styles.conteudo_edit}>
                    <h1>Guia do Usuário</h1>
                    <div className={styles.formGroup}>
                        <h2>Primeiro Acesso</h2></div>
                    <div className={styles.formGroup}>
                        <p>Para acessar o SICAP(Sistema de Informações e Controle de Atendimento ao Público) é
                            preciso cadastrar-se. Digite o endereço URL do SICAP no seu navegador, depois,
                            clique em “Cadastre-se”, opção localizada na caixa “Login”, do lado direito da página.</p></div>
                    <br />
                    <div className={styles.formGroup}><img src={Cadastro} alt='Imagem Cadastro' className={styles.image2} /></div>

                    <br />
                    <div className={styles.formGroup}>
                        <p>Ao clicar em “Cadastre-se”, aparecerá o formulário abaixo:</p></div>
                    <br />
                    <div className={styles.formGroup}><img src={FormCadastro} alt='Imagem FormCadastro' className={styles.image2} /></div>
                    <br />
                    <div className={styles.formGroup}>
                        <p>Preencha todos os campos, por fim, selecione a caixa: “Li e aceito os Termos e Condições”.
                            Depois, clique em “Cadastrar”. O sistema imediatamente direcionará você à página principal
                            da sua conta no SICAP.</p></div><br />
                    <div className={styles.formGroup}>
                        <h3>Nesta seção você pode:</h3></div>
                    <div className={styles.formGroup}>
                        <p>Editar o seu perfil de usuário com suas informações;</p></div>
                    <div className={styles.formGroup}>
                        <p>Cadastrar uma requisição para agendamento de consulta;</p></div>
                    <div className={styles.formGroup}>
                        <p>Listar suas requisições já cadastradas; e</p></div>
                    <div className={styles.formGroup}>
                        <p>Consultar requisições respondidas através das fichas cadastradas;</p></div>
                    <br />
                    <div className={styles.formGroup}><img src={Perfil} alt='Imagem Perfil' className={styles.image2} /></div>
                    <br /><br /><div className={styles.formGroup}>
                        <h2>Acessando o SICAP</h2></div>
                    <div className={styles.formGroup}>
                        <p>Para acessar o SICAP, entre no site e digite seu “E-mail e Senha” na caixa “Login”,
                            disponível na página inicial do site.</p></div>
                    <br />
                    <div className={styles.formGroup}><img src={Login} alt='Imagem Login' className={styles.image2} /></div>
                    <br />
                    <div className={styles.formGroup}>
                        <p>Para registrar uma requisição, é preciso clicar no botão no menu lateral: “Nova Requisição”.</p></div>
                    <br />
                    <div className={styles.formGroup}><img src={requisicao} alt='Imagem requisicao' className={styles.image2} /></div>
                    <br />
                    <div className={styles.formGroup}>

                        <p>
                            Registro Rápido de requisicão e Fácil em 3 Passos
                        </p>

                    </div>
                    <div className={styles.formGroup}>
                        <p>
                            <strong>Passo 1: Dados Pessoais</strong>
                            <br />
                            Preencha os campos relacionados aos dados pessoais, como nome completo, RG, Cartão SUS, descrição dos sintomas. Após preencher todos os campos, clique no botão "Próximo" para avançar para a próxima etapa.
                        </p>
                    </div>
                    <br />
                    <div className={styles.formGroup}>
                        <img src={DadosPessoais} alt='Imagem DadosPessoais' className={styles.image2} />

                    </div>
                    <br />
                    <div className={styles.formGroup}>
                        <p>

                            <strong>Passo 2: Dados de Endereço</strong>
                            <br />

                            Nesta etapa, forneça os dados relacionados ao endereço, como rua, número, bairro, CEP, complemento. Se precisar fazer alguma alteração nos dados anteriores, clique no botão "Anterior" para voltar à etapa anterior. Depois de preencher os campos, clique em "Próximo".
                        </p>
                    </div>
                    <br />
                    <div className={styles.formGroup}>
                        <img src={DadosEndereco} alt='Imagem DadosEndereco' className={styles.image2} />


                    </div>
                    <br />
                    <div className={styles.formGroup}>
                        <p>
                            <strong>Passo 3: Dados da Requisição</strong>
                            <br />
                            Insira os detalhes específicos da requisição nesta etapa. Que especialidade e Preferencial. Se necessário, use o botão "Anterior" para revisar os dados anteriores. Após preencher todos os campos,Marque caixa de selecão se você concorda com os termos".
                        </p>
                    </div>
                    <br />
                    <div className={styles.formGroup}>
                        <img src={DadosRequisicao} alt='Imagem DadosRequisicao' className={styles.image2} />
                    </div>
                    <br />
                    <div className={styles.formGroup}>
                        <p>
                            Na última etapa, confira as informações estão corretas. Em seguida, clique no botão "Cadastrar" para concluir o processo de requisição
                        </p>
                    </div>
                    <br />
                    <div className={styles.formGroup}>
                        <img src={Requisicao3} alt='Imagem Requsicao3' className={styles.image2} />
                    </div>
                    <br />


                    <div className={styles.formGroup}>
                        <p>Pronto! Sua requisição foi registrada. Os agentes do SICAP irão registrar sua requisição
                            através de uma ficha, e logo depois será enviado para seu e-mail o dia e hora que foi agendado
                            sua consulta ou você poderá acompanhar através de "Fichas Registradas".</p></div>
                    <div className={styles.formGroup}><p>OBSERVAÇÃO: O órgão tem até 30 dias para responder a sua requisição para agendamento de consulta.</p></div><br /><br />

                </div></div>
            <Footer />
        </>
    )
}

export default GuiaDoUsuario;