import React from 'react';
import styles from '../styles/ListarRequisicao.module.css';
const FormStep1 = ({ requisicaoEdit, setRequisicaoEdit, handleNextStep }) => {
  return (
   <div>
 <div className={styles.formularioEdicao}>

    <h3>Dados Pessoais</h3>
      <label htmlFor="nomeCompleto">Nome Completo:</label>
            <input
              type="text"
              placeholder="Nome Completo"
              value={requisicaoEdit?.nomeCompleto || ""}
              onChange={(e) =>
                setRequisicaoEdit({
                  ...requisicaoEdit,
                  nomeCompleto: e.target.value,
                })
              }
              required
            />
            <label htmlFor="rg">RG:</label>
            <input
              type="text"
              placeholder="RG"
              value={requisicaoEdit?.rg || ""}
              onChange={(e) =>
                setRequisicaoEdit({ ...requisicaoEdit, rg: e.target.value })
              }
              required
            />
            <label htmlFor="Cartão SUS">Cartão SUS:</label>
            <input
              type="text"
              placeholder="Cartão SUS"
              value={requisicaoEdit?.cartaoSUS || ""}
              onChange={(e) =>
                setRequisicaoEdit({
                  ...requisicaoEdit,
                  cartaoSUS: e.target.value,
                })
              }
              required
            />
            <label htmlFor="Descrição dos Sintomas">
              Descrição dos Sintomas:
            </label>
            <input
              type="text"
              placeholder="Descrição dos Sintomas"
              value={requisicaoEdit?.descricaoDosSintomas || ""}
              onChange={(e) =>
                setRequisicaoEdit({
                  ...requisicaoEdit,
                  descricaoDosSintomas: e.target.value,
                })
              }
              required
            />
             <button onClick={handleNextStep}>Próximo</button>
            </div>
            </div>
        
  );
};

export default FormStep1;
