import SideBar from "../menus/SideBar";
import styles from '../styles/MinhaFicha.module.css'
import axios from "axios";
//import {BsPencil, BsFillTrashFill} from "react-icons/bs"
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";



function MinhaFicha() {
    const [fichas, setFichas] = useState([]);
    const [mes, setMes] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [agentes, setAgentes] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [requisicoes, setRequisicoes] = useState([]);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true); // Adicione o estado para controle de carregamento

  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navigate("/acessonegado");
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500);
    } else {
      axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/login', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          const logMessages = response.data.log_messages;
          if (logMessages && logMessages.length > 0) {
            const lastLogMessage = logMessages[logMessages.length - 1];
            const userIdFromLog = extractUserIdFromLog(lastLogMessage);
            setUserId(userIdFromLog);
          }
        })
        .catch((error) => {
          console.error(error);
        });

        axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/ficha', {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
            .then((response) => {
              console.log("Ficha:", response.data); // Adicione este log para verificar as requisições
              setFichas(response.data);
              setLoading(false);
            })
            .catch((error) => {
              console.error(error);
              setLoading(false);
            });
          
    }
  }, [navigate]);

  const extractUserIdFromLog = (logMessage) => {
    const userIdRegex = /Usuário com ID (\d+) acessou/;
    const match = logMessage.match(userIdRegex);
    console.log("Original Log Message:", logMessage);
    console.log("Matched User ID:", match ? match[1] : null);
    return match ? match[1] : null;
  };

  
    

   
      
    useEffect(() => {
      axios
        .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/mes")
        .then((response) => setMes(response.data))
        .catch((error) => console.log(error));
    }, []);
    useEffect(() => {
      axios
        .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/especialidade")
        .then((response) => setEspecialidades(response.data))
        .catch((error) => console.log(error));
    }, []);
    useEffect(() => {
      axios
        .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/agente")
        .then((response) => setAgentes(response.data))
        .catch((error) => console.log(error));
    }, []);
    useEffect(() => {
      axios
        .get("https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente")
        .then((response) => setPacientes(response.data))
        .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/ficha')
          .then((response) => setFichas(response.data))
          .catch((err) => console.log(err));
      }, []);
      useEffect(() => {
        axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicao')
          .then((response) => setRequisicoes(response.data))
          .catch((err) => console.log(err));
      }, []);

      
     

      // Filtrar as requisições do paciente logado
const filteredFicha = fichas.filter((ficha) => {
    const isMatchingPatient = ficha.paciente.id.toString() === userId;
  
    // Adicione o console.log para verificar o userId
    console.log("UserID:", userId);
    console.log("PacienteID na Requisição:", ficha.paciente.id);
    console.log("Corresponde ao Paciente?", isMatchingPatient);
  
    return isMatchingPatient;
  });


   
 

  return (
    <>
      <SideBar/>
      <div className={styles.containerAdminNavBar}>
      <div className={styles.container}>
            <main>
            <h1>Fichas</h1>      
             
        {loading ? (
          <p>Carregando...</p>
        ) : (
          <>
            {filteredFicha.length === 0 ? (
              <p>Não há requisições ainda.</p>
            ) : (
              <div>
                <table className={`${styles.tabela}`}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>MENSAGEM</th>
                      <th>NOME COMPLETO</th>
                      <th>RG</th>
                      <th>CARTÃO SUS</th>
                      <th>PREFERENCIAL</th>
                      <th>DESCRICÃO DOS SINTOMAS</th>
                      <th>ESPECIALIDADE</th>
                      <th>MES</th>
                      <th>AGENTE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredFicha.map((ficha) => {
                      return (
                        <tr key={ficha.id}>
                          <td>{ficha.id}</td>
                          <td>{ficha.mensagem}</td>
                          <td>{ficha.requisicao ? ficha.requisicao.nomeCompleto : ""}</td>
                          <td>{ficha.requisicao ? ficha.requisicao.rg : ""}</td>
                          <td>{ficha.requisicao ? ficha.requisicao.cartaoSUS : ""}</td>
                          <td>{ficha.requisicao ? ficha.requisicao.preferencial.nome : ""}</td>
                          <td>{ficha.requisicao ? ficha.requisicao.descricaoDosSintomas : ""}</td>
                          <td>{ficha.especialidade ? ficha.especialidade.nome : ""}</td>
                          <td>{ficha.mes ? ficha.mes.nome : ""}</td>
                          <td>{ficha.agente ? ficha.agente.nome : ""}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table><br/>
              </div>
              
            )}
          </>
        )}
        </main>
        </div>
      </div>
    </>
    )
}

export default MinhaFicha;