import React from 'react';
import { BsPencil, BsFillTrashFill } from 'react-icons/bs';
import styles from '../styles/UserCard.module.css'; 

const UserProfileCard = ({ paciente, editPaciente, removePaciente }) => {
  return (
    <div className={styles.card}>
    <div>
      <h3>PERFIL</h3>
      <br />
      </div>
        
      
      <div>
        <strong>NOME:</strong> {paciente.nome}
      </div>
      <div>
        <strong>EMAIL:</strong> {paciente.email}
      </div>
      <div>
        <strong>TELEFONE:</strong> {paciente.telefone}
      </div>
      <div>
        <button onClick={() => editPaciente(paciente)}>
          <BsPencil />
        </button>
        <button className={styles.deleteButton} onClick={() => removePaciente(paciente.id)}>
          <BsFillTrashFill />
        </button>
      </div>
    </div>
  );
};

export default UserProfileCard;
