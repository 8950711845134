import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import SideBaradmin from '../menus/SideBarAdmin';
import styles from '../styles/ListaAgente.module.css';

function ListarAgentes() {
  const navegação = useNavigate();
  const [agentes, setAgentes] = useState([]);
  const [editAgente, setEditAgente] = useState(null);
  const [nomeInvalido, setNomeInvalido] = useState(false);
  const [emailInvalido, setEmailInvalido] = useState(false);
  const [senhaInvalida, setSenhaInvalida] = useState(false);
  const [registroInvalido, setRegistroInvalido] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  useEffect(() => {
    // Verifica se o usuário é administrador
    const isAdmin = localStorage.getItem("isAdmin");

    if (!isAdmin || isAdmin !== "true") {
      // Se não for um administrador, redireciona para uma página não autorizada
      alert("Você não tem permissão para acessar esta página.");
      navegação("/acessonegado"); // Substitua pela rota desejada
    }

    // Fetch agent data when the component mounts
    axios.get("https://tesw.tecnologiaemsoftwareweb.com.br/api/agente")
      .then((response) => {
        setAgentes(response.data);
      })
      .catch((error) => console.log(error));
  }, [navegação]);

  const editAgenteHandler = (agente) => {
    setEditAgente(agente);
    setMostrarFormulario(true);
  };

  const saveEditAgente = () => {
    // Verificar se os campos são válidos
    if (!editAgente.nome || !editAgente.email || !editAgente.senha || !editAgente.registroDeTrabalho) {
      alert("Todos os campos são obrigatórios!");
      return;
    }

    // Verificar se o nome de usuário tem entre 5 e 15 caracteres (letras ou números)
    const usernameRegex = /^[a-zA-Z0-9]{5,15}$/;
    if (!usernameRegex.test(editAgente.nome)) {
      alert("Nome de usuário inválido! O nome de usuário deve conter apenas letras e números e ter entre 5 e 15 caracteres.");
      return;
    }

    // Verificar senha
    const senhaRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    if (!senhaRegex.test(editAgente.senha)) {
      setSenhaInvalida(true);
      alert("Senha inválida! A senha deve ter pelo menos 8 caracteres, incluindo letras maiúsculas, minúsculas, números e caracteres especiais.");
      return;
    }

    // Verificar email
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(editAgente.email)) {
      setEmailInvalido(true);
      alert("Email inválido! Por favor, insira um endereço de email válido.");
      return;
    }

    // Verificar comprimento do registro de trabalho
    const registroDeTrabalho = parseInt(editAgente.registroDeTrabalho);
    if (isNaN(registroDeTrabalho) || registroDeTrabalho.toString().length !== 7) {
      setRegistroInvalido(true);
      alert('Número de registro de trabalho inválido. Deve conter no máximo 7 dígitos.');
      return;
    }

    // Atualizar o agente no servidor
    const confirmarEdicao = window.confirm("Tem certeza que deseja salvar as edições?");
    if (confirmarEdicao) {
      axios.put(`https://tesw.tecnologiaemsoftwareweb.com.br/api/agente/${editAgente.id}`, editAgente)
        .then((response) => {
          const updatedAgentes = agentes.map((agente) => (agente.id === response.data.id ? response.data : agente));
          setAgentes(updatedAgentes);
          setEditAgente(null);
          setSucesso(true);
          setTimeout(() => {
            setSucesso(false);
            window.location.reload();
          }, 1000);
        })
        .catch((error) => console.log(error));
    }
  };

  const removeAgente = (id) => {
    const confirmarExclusao = window.confirm("Tem certeza que deseja excluir a sua conta?");
    if (confirmarExclusao) {
      axios.delete(`https://tesw.tecnologiaemsoftwareweb.com.br/api/agente/${id}`)
        .then((response) => {
          const updatedAgentes = agentes.filter((agente) => agente.id !== id);
          setAgentes(updatedAgentes);
          alert("Conta excluída com sucesso");
          navegação('/listaagentes');
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <SideBaradmin />
      <div className={styles.containerAdminNavBar}>
        {sucesso && <div className={styles.mensagemSucesso}>Atualizado com sucesso!</div>}
        <div className={styles.container}>
          <main>
            <h1>Agentes</h1>
            <br />
            {mostrarFormulario ? (
              <div className={styles.editForm}>
                {/* Formulário de edição */}
                <div className={styles.formGroup}>
                  <label>Nome:</label>
                  <input
                    type="text"
                    value={editAgente?.nome || ''}
                    onChange={(e) => setEditAgente({ ...editAgente, nome: e.target.value })}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Email:</label>
                  <input
                    type="email"
                    value={editAgente?.email || ''}
                    onChange={(e) => setEditAgente({ ...editAgente, email: e.target.value })}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Senha:</label>
                  <input
                    type="password"
                    value={editAgente?.senha || ''}
                    onChange={(e) => setEditAgente({ ...editAgente, senha: e.target.value })}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Registro de Trabalho:</label>
                  <input
                    type="text"
                    value={editAgente?.registroDeTrabalho || ''}
                    onChange={(e) => setEditAgente({ ...editAgente, registroDeTrabalho: e.target.value })}
                  />
                </div>
                <button onClick={saveEditAgente}>Salvar</button>
              </div>
            ) : (
              <div className={styles.cardContainer}>
                <table className={styles.tabela}>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Email</th>
                      <th>Senha</th>
                      <th>Registro de Trabalho</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentes.map((agente) => (
                      <tr key={agente.id} className={styles.tabelaTr}>
                        <td>{agente.nome}</td>
                        <td>{agente.email}</td>
                        <td>{agente.senha}</td>
                        <td>{agente.registroDeTrabalho}</td>
                        <td>
                          <button className={`${styles.botaoRover} ${styles.azul}`} onClick={() => editAgenteHandler(agente)}>
                            <BsPencil />
                          </button>
                          <button className={`${styles.botaoRover} ${styles.vermelho}`} onClick={() => removeAgente(agente.id)}>
                            <BsFillTrashFill />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </main>
        </div>
      </div>
    </>
  );
}

export default ListarAgentes;
