import React, { useState } from 'react';
import styles from '../styles/FormCadastro.module.css';
import Button from '../menus/Button';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function FormCadastroPaciente() {
  const [novoPaciente, setNovoPaciente] = useState({
    nome: '',
    email: '',
    senha: '',
    telefone: '',
  });
  const navigate = useNavigate();
  const [concordaTermos, setConcordaTermos] = useState(false); // Novo estado para rastrear o consentimento
  const handleChangeCheckbox = (e) => {
    setConcordaTermos(e.target.checked);
  };
  // Validar telefone
const validarTelefone = (telefone) => {
  const regexTelefone = /^\d{11}$/; // Assume que o telefone deve ter exatamente 10 dígitos numéricos
  return regexTelefone.test(telefone);
};

  const validarSenha = (senha) => {
    // eslint-disable-next-line
    const regexSenha = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
    return regexSenha.test(senha);
  };

  const validarEmail = (email) => {
    const regexEmail = /^\S+@\S+\.\S+$/;
    return regexEmail.test(email);
  };


  const validarNome = (nome) => {
    const regexNome = /^[a-zA-Z0-6_]{5,21}$/; // Assume que o nome de usuário deve ter pelo menos 5 letras e pode incluir underscores
    return regexNome.test(nome);
  };

  
  
  const handleSubmit = (event) => {
    event.preventDefault();
     // Verificar se o usuário concordou com os termos
     if (!concordaTermos) {
      alert("Você deve concordar com os termos e condições antes de prosseguir.");
      return;
    }

    // Validar senha
    if (!validarSenha(novoPaciente.senha)) {
      alert("A senha deve ter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial.");
      return;
    }

    // Validar email
    if (!validarEmail(novoPaciente.email)) {
      alert("Por favor, insira um endereço de email válido.");
      return;
    }
    // Uso das funções de validação
if (!validarTelefone(novoPaciente.telefone)) {
  alert("Por favor, insira um número de telefone válido com 11 dígitos numéricos.");
  return;
}

if (!validarNome(novoPaciente.nome)) {
  alert("O nome de usuário deve ter entre 5 e 21 caracteres alfanuméricos ou underscores.");
  return;
}

    // Cadastrar paciente
    axios
      .post('https://tesw.tecnologiaemsoftwareweb.com.br/api/paciente', novoPaciente) // Atualize a URL para o endpoint de cadastro de pacientes
      .then((response) => {
        console.log(response.data);
        localStorage.setItem("accessToken", response.data.access_token);
        alert('Cadastro de paciente feito com sucesso');
        navigate('/login');
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNovoPaciente((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className={styles.formGroup}>
      <form className={styles.content_form} onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nome"
          name="nome"
          value={novoPaciente.nome}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={novoPaciente.email}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          placeholder="Senha"
          name="senha"
          value={novoPaciente.senha}
          onChange={handleChange}
          required
        />
        <input
          type="tel"
          placeholder="Telefone"
          name="telefone"
          value={novoPaciente.telefone}
          onChange={handleChange}
          required
        />
        <label>
          <input
            type="checkbox"
            name="concordaTermos"
            checked={concordaTermos}
            onChange={handleChangeCheckbox}
            required
          />
          Li e aceito os&nbsp;<Link to='/lgpd' target='_blank'>Termos e Condições.</Link>
        </label>

        <Button type="submit" text="Cadastrar"></Button>
      </form>
    </div>
  );
}

export default FormCadastroPaciente;
