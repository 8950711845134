import React from 'react';
import Layout from '../menus/Layout';
import FormCadastro from '../form/FormCadastro';
import styles from '../styles/Cadastro.module.css';

function Cadastro() {
  return (
    <Layout>
      <div className={styles.content_container}>
        <div className={styles.form_section}>
          <h1 className={styles.title}>Cadastre-se</h1>
          <hr />
          <FormCadastro />
        </div>
      </div>
    </Layout>
  );
}

export default Cadastro;
