import React from "react";
import { useNavigate } from "react-router-dom";
import styles from '../styles/AcessoNegado.module.css';

function AcessoNegado() {
  const navigate = useNavigate();

  const handleRedirecionamento = () => {
    navigate("/login"); // Redireciona para a página de login quando o acesso é negado
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Acesso Negado</h1>
      <p className={styles.message}>Você não tem permissão para acessar esta página.</p>
      <button className={styles.button} onClick={handleRedirecionamento}>
        Voltar para a página de Login
      </button>
    </div>
  );
}

export default AcessoNegado;
