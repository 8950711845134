import React from 'react';


const Step2 = ({ novaRequisicao, handleEnderecoChange, handlePrevStep,handleNextStep }) => {
    return (
      <div>
  
         <h3> Dados De Endereço</h3>    
        <label>Rua:</label>
        <input
          type="text"
          placeholder="Digite o nome da sua rua"
          name="rua"
          value={novaRequisicao.endereco.rua}
          onChange={handleEnderecoChange}
          required
        />
  
        <label>Bairro:</label>
        <input
          type="text"
          placeholder="Digite o nome do seu bairro"
          name="bairro"
          value={novaRequisicao.endereco.bairro}
          onChange={handleEnderecoChange}
          required
        />
  
        <label>CEP:</label>
        <input
          type="text"
          placeholder="Digite seu CEP (8 dígitos)"
          name="cep"
          value={novaRequisicao.endereco.cep}
          onChange={handleEnderecoChange}
          required
        />
  
        <label>Número:</label>
        <input
          type="text"
          placeholder="Digite o número da sua casa"
          name="numero"
          value={novaRequisicao.endereco.numero}
          onChange={handleEnderecoChange}
          required
        />
  
        <label>Complemento:</label>
        <input
          type="text"
          placeholder="Digite informações adicionais (opcional)"
          name="complemento"
          value={novaRequisicao.endereco.complemento}
          onChange={handleEnderecoChange}
        />
  
        <button type="button" onClick={handlePrevStep}>
          Anterior
        </button>
        <button type="button" onClick={handleNextStep}>
          Próximo
        </button>
      </div>
    );
  };

export default Step2;
