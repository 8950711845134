import React, { useState, useEffect } from 'react';
import styles from '../styles/EnviarEmail.module.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SideBarAgente from "../menus/SideBar.Agente";

function EnviarEmail() {
  const navegação = useNavigate();
  const [fileData, setFileData] = useState(null);
  const [tituloGrupo, setTituloGrupo] = useState('');
  const [mensagemManual, setMensagemManual] = useState('');
  const [numEmails, setNumEmails] = useState(0);
  const [enviando, setEnviando] = useState(false);

  useEffect(() => {
    const accessTokenAgente = localStorage.getItem("accessTokenAgente");

    if (!accessTokenAgente) {
      // Redirecionar para a página de acesso negado
      navegação("/acessonegado");

      // Mostrar alerta ao usuário após um pequeno atraso
      setTimeout(() => {
        alert("Você não tem permissão para acessar esta página.");
      }, 500); // Ajuste o tempo de atraso conforme necessário
    }

  }, [navegação]);
  const fetchEmailData = async () => {
    try {
      const response = await axios.get('https://tesw.tecnologiaemsoftwareweb.com.br/get_emails');
      const emailsData = response.data;

      if (emailsData.length === 0) {
        alert('Nenhum email encontrado');
        return;
      }

      const firstEmail = emailsData[0];

      setFileData(emailsData);
      setTituloGrupo(firstEmail.titulo);
      setMensagemManual(firstEmail.mensagem);
      setNumEmails(emailsData.length);
    } catch (error) {
      console.error(error);
      alert('Ocorreu um erro ao buscar os emails.');
    }
  };

  useEffect(() => {
    fetchEmailData();
  }, []); // Adicione a função fetchEmailData como dependência

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      titulo_grupo: tituloGrupo,
      mensagem_manual: mensagemManual,
    };

    try {
      setEnviando(true);

      const response = await axios.post('https://tesw.tecnologiaemsoftwareweb.com.br/send', payload);
      alert(response.data.message);
      navegação('/agente');


      const requisicaoFichaData = fileData.map(email => ({
        ficha: { id: email.ficha_id },
        requisicao: { id: email.requisicao_id }
      }));


      const saveRequisicaoFichaPromises = [];

      for (const data of requisicaoFichaData) {
        saveRequisicaoFichaPromises.push(axios.post('https://tesw.tecnologiaemsoftwareweb.com.br/api/requisicaoficha', data));
      }

      try {
        await Promise.all(saveRequisicaoFichaPromises);

        // Atualizar os dados buscando novamente
        await fetchEmailData();
      } catch (error) {
        console.error(error);
        alert('Atenção: Um ou mais pacientes receberam a mensagem, mas já estão vinculados ao referente ficha.');
      }




    } catch (error) {
      console.error(error);
      alert('Ocorreu um erro ao enviar o e-mail.');
    } finally {
      setEnviando(false);
    }
  };

  return (
    <>
      <SideBarAgente />

      <div className={styles.containerAdminNavBar}>
        <div className={styles.container}>
          <main>
            <h1>Mensagem</h1>
            <div className={styles.conteudo}></div>

            <form onSubmit={handleSubmit}>
              <div className={styles.editForm}>
                <div className={styles.formGroup}>
                  <label>Título :</label>
                  <span id="titulo_grupo">{tituloGrupo}</span>
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="mensagem_manual">Mensagem Padrão:</label>
                  <textarea
                    id="mensagem_manual"
                    name="mensagem_manual"
                    rows="4"
                    cols="50"
                    required
                    placeholder={mensagemManual ? '' : 'Digite a mensagem'}
                    value={mensagemManual}
                    onChange={(e) => setMensagemManual(e.target.value)}
                  ></textarea>
                </div>
                <div className={styles.formGroup}>
                  <label>Número de Pacientes Selecionados:</label>
                  <span id="num_emails">{numEmails}</span>
                </div>
                <div className={styles.editForm}>
                  <button type="submit" disabled={enviando}>
                    {enviando ? 'Enviando...' : 'Enviar'}
                  </button>
                </div>
              </div>
            </form>
          </main>
        </div>
      </div>
    </>
  );
}

export default EnviarEmail;
